import React from "react";
import {notification, Layout} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

import SessionsList from "./SessionsList"
import TraceableNotificationDescription from "./TraceableNotificationDescription";

import {Repo} from "../data/Repos";

import { useSessionBrowsingContext } from "../data/SessionBrowsing";
import {
    SessionStub,
    CreateSessionResultCode,
    useLoadSession,
    useSession,
    createSession,
    deleteSession,
} from "../data/SolverSession";
import { useSolverInterfaceContext } from "../data/SolverInterface";

const { Sider } = Layout;

const SessionsSider: React.FC = () => {
    const session = useSession();
    const loadSession = useLoadSession()

    const { currentUser, activeRepo, loadingRepos } = useSolverInterfaceContext();
    const { updateTitle } = useSessionBrowsingContext();

    const [api, contextHolder] = notification.useNotification();

    const [collapsed, setCollapsed] = React.useState(false);

    const buildSessionsList = () => {
        if (loadingRepos) {
            return undefined;
        } else if (!activeRepo) {
            return undefined;
        } else {
            return <SessionsList
                currentUser={currentUser}
                repo={activeRepo}
                activeSession={session}
                onNewSession={async (branch: string) => {
                    try {
                        const result = await createSession(activeRepo.fullName, branch)

                        if (result?.session) {
                            await loadSession(result.session.session_id)
                        } else {
                            api.error({
                                message: "Failed to create session",
                                description: createFailedMessage(result.responseCode, branch, activeRepo),
                                placement: "bottomRight"
                            });
                        }
                    } catch (error: unknown) {
                        api.error({
                            message: "Failed to create session",
                            description: error instanceof Error ? error.toString() : "Unknown error",
                            placement: "bottomRight"
                        });
                    }
                }}
                onSwitchSession={(s: SessionStub) => {
                    if (s && s.session_id !== session?.session_id) {
                        return loadSession(s.session_id);
                    } else {
                        return Promise.resolve();
                    }
                }}
                onUpdateTitle={async (session: SessionStub, newName: string): Promise<boolean> => {
                    if (currentUser?.id !== session.user_id) return false;

                    const updatedTitle = updateTitle(session.session_id, newName)

                    if (!updatedTitle) {
                        api.error({
                            message: "Updating failed",
                            description: <TraceableNotificationDescription
                                description="Failed to update Session."
                                session_id={session.session_id}
                            />,
                            placement: "bottomRight"
                        });
                    }

                    return updatedTitle;
                }}
                onDeleteSession={async (sessionToDelete: SessionStub) => {
                    if (currentUser?.id !== sessionToDelete.user_id) return;

                    const deleted = await deleteSession(sessionToDelete.session_id);

                    if (deleted && sessionToDelete.session_id === session?.session_id) {
                        await loadSession(undefined);
                    }

                    if (!deleted) {
                        api.error({
                            message: "Failed to delete Session",
                            placement: "bottomRight"
                        });
                        return;
                    }
                }}
                notification={api}
            />
        }
    }

    const triggerWidth = collapsed ? 20 : 15;

    return (
        <>
            <Sider
                collapsible
                collapsedWidth={0}
                collapsed={collapsed}
                trigger={collapsed ? <RightOutlined className="sider-trigger" /> : <LeftOutlined className="sider-trigger"/>}
                onCollapse={value => setCollapsed(value)}
                width={300}
                zeroWidthTriggerStyle={{
                    top: "350px",
                    right: "-8px",
                    width: triggerWidth,
                    borderRadius: "0",
                    fontSize: "1.25em",
                    background: "transparent",
                    backgroundColor: "#303030"
                }}
                className={`sider ${collapsed ? "sider-collapsed" : "sider-expanded"}`}
            >
                {buildSessionsList()}
            </Sider>
            {contextHolder}
        </>
    );
};

const createFailedMessage = (code: CreateSessionResultCode | undefined, branch: string, repo: Repo) => {
    switch (code) {
        case CreateSessionResultCode.REPO_NOT_CONFIGURED:
            return `${repo.fullName} is not configured.`
        case CreateSessionResultCode.BRANCH_NOT_FOUND:
            return `Branch "${branch}" not found in ${repo.fullName}.`
        case CreateSessionResultCode.FAILED_TO_CREATE_BRANCH:
            return `Failed to create branch "${branch}" for ${repo.fullName}.`
        default:
            return "Failed to create session."
    }
}

export default SessionsSider;
