"use client";
import classNames from "classnames";
import React, { useState } from "react";

import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Image } from "antd";

import Message, { MessageProps } from "./Message";
import { MessageType } from "./MessageType";

import { TurnEventType } from "../data/SolverInterfaceEvent";
import "./MessageGroup.css";

export interface MessageGroupProps {
    messages: MessageProps[];
    avatar: string;
    messageType: MessageType;
    collapsible?: boolean;
    collapsed: boolean;
}

const MessageGroup: React.FC<MessageGroupProps> = ({
    messages,
    messageType,
    avatar,
    collapsible = true,
    collapsed = false,
}) => {
    const [isExpanded, setIsExpanded] = useState(!collapsed);

    if (messages.length === 0) return null;

    const messageTypeToMessageGroupClass = () => {
        switch (messageType) {
            case MessageType.USER:
                return "message-group-user";
            case MessageType.AGENT:
                return "message-group-agent";
        }
    }

    const messageGroupClass = classNames({
        [messageTypeToMessageGroupClass()]: true,
    })

    const collapsibleMessagesClass = classNames({
        "message-group-collapsible-messages": true,
        "message-group-collapsible-messages-collapsed": !isExpanded,
        "message-group-collapsible-messages-expanded": isExpanded,
        "user": avatar.includes('user')
    })

    const avatarClass = classNames({
        "message-group-avatar-image": true,
        "user": avatar.includes('user')
    })

    let footerMessages: MessageProps[] = [];

    if (messages.length > 0) {
        // Take last 2 if the last event type is SUBMIT or RESOURCES_EXHAUSTED
        const lastMessage = messages[messages.length - 1];
        if (lastMessage.eventType === TurnEventType.SUBMIT || lastMessage.eventType === TurnEventType.RESOURCES_EXHAUSTED) {
            footerMessages = messages.slice(-2);
            messages = messages.slice(0, -2);
        }
        else {
            footerMessages = messages.slice(-1);
            messages = messages.slice(0, -1);
        }
    }

    const buildCollapseTrigger = () => {
        if (!collapsible) return null;

        if (messages.length === 0) return null;

        return <div className="message-group-header">
            <Button
                className="message-group-header-button"
                onClick={() => setIsExpanded(expanded => !expanded)}
            >
                {isExpanded ? <DownOutlined /> : <RightOutlined />}
                {buttonText()}
            </Button>
        </div>
    }

    const buttonText = () => {
        const messageCountText = messages.length === 1 ? "message" : "messages";

        if (isExpanded) {
            return `Hide ${messages.length} ${messageCountText}`;
        } else {
            return `Show ${messages.length} ${messageCountText}`;
        }
    }

    return (
        <>
            <div className={messageGroupClass}>
                {messageType === MessageType.AGENT && <div className="message-group-avatar message-group-avatar-agent">
                    <Image
                        className={avatarClass}
                        src={avatar}
                        width={24}
                        height={24}
                        preview={false}
                    />
                </div>}
                <div className="message-group-messages">
                    {buildCollapseTrigger()}
                    {messages.length > 0 &&
                        <div className={collapsibleMessagesClass}>
                            <div className="message-group-collapsible-messages-inner">
                                {messages.map((messageProps: MessageProps) => <Message key={messageProps.key} {...messageProps} />)}
                            </div>
                        </div>
                    }
                    {footerMessages.length > 0 &&
                        <div className="message-group-footer">
                            {footerMessages.map((messageProps: MessageProps) => <Message key={messageProps.key} {...messageProps} />)}
                        </div>
                    }
                </div>
                {messageType === MessageType.USER && <div className="message-group-avatar message-group-avatar-user">
                    <Image
                        className={avatarClass}
                        src={avatar}
                        width={24}
                        height={24}
                        preview={false}
                    />
                </div>}
            </div>
        </>
    )
};

export default MessageGroup;
