"use client";
import { Card, Col, List, Row, Tag, Typography } from "antd";
import React from "react";

import { RelevantFile } from '../data/TurnEventContent';

const fileExtensionColorMap: { [key: string]: string } = {
    ".ts": "cyan",
    ".tsx": "cyan",
    ".js": "gold",
    ".jsx": "purple",
    ".py": "green",
    ".java": "red",
    ".cpp": "magenta",
    ".html": "volcano",
    ".css": "geekblue",
    ".json": "lime",
    ".md": "orange",
    ".txt": "default"
};

function getFileExtension(filePath: string): string | null {
    const pathSegments = filePath.split('/');
    const fileName = pathSegments.pop() || '';
    const ext = fileName.includes('.') ? fileName.split('.').pop() : '';
    if (!ext) return null;
    return ext.length > 8 ? `${ext.substring(0, 8)}...` : `.${ext}`;
}

function getFileExtensionAsTag(filePath: string) {
    const ext = getFileExtension(filePath);
    const color = fileExtensionColorMap[ext ?? ""] || "default";
    return <Tag color={color} style={{ margin: "5px" }}>{ext ?? "?"}</Tag>
}


const CollapsedRelevanceRow: React.FC<{ file: RelevantFile }> = ({ file }) => {
    return <Row>
        <Col span={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {getFileExtensionAsTag(file.path)}
        </Col>
        <Col
            span={12}
            style={{
                margin: "5px",
                marginLeft: "20px"
            }}>
            <Typography.Text className="diff-header-path">
                &lrm;{file.path}
            </Typography.Text>
        </Col>
    </Row>
}

export const RelevantFilesCard: React.FC<{
    files: RelevantFile[];
}> = ({ files }) => {
    return <>
        <Typography.Text type="secondary" style={{ fontSize: "12px", marginLeft: "3px" }}>Found {files.length} relevant files
        </Typography.Text>
        <Card>
            <List
                size="small"
                dataSource={files.sort((a, b) => b.score - a.score)}
                renderItem={item => <CollapsedRelevanceRow file={item} />}
            />
        </Card>
    </>;
}
