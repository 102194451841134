import { useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';

export const useDebounce = (
    callback: (...args: unknown[]) => void,
    timeout: number
) => {
    const ref = useRef<(...args: unknown[]) => void>();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return _.debounce(func, timeout);
    }, []);

    return debouncedCallback;
};
