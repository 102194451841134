import React from "react";
import {
    FileData,
    HunkData,
    ChangeData,
    Decoration,
    markEdits,
    tokenize,
    Source,
    Hunk,
    getChangeKey,
    parseDiff,
    TokenizeEnhancer
} from "react-diff-view";
import refractor from 'refractor';
import {Button, Tooltip, Typography } from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, ArrowRightOutlined, CloseOutlined, CopyOutlined} from "@ant-design/icons";
import {ChangedFile, ChangeSet, FileImage} from "../data/SolverSession";

const EXPAND_LINES = 5;

const DiffHeader: React.FC<{ fileData: FileData }> = ({fileData}) => {
    const renamed = fileWasRenamed(fileData.type, fileData.similarity);

    // We insert a left-to-right mark (&lrm;) to ensure that leading punctuation
    // in file paths is not interpreted as right-to-left text, since |diff-header-path|
    // has a direction of rtl to force overflow of the leftmost characters.
    let path;
    if (renamed) {
        path = <span className="diff-header-path-rename-container">
            <Typography.Text className="diff-header-path">&lrm;{fileData.oldPath}</Typography.Text>
            <ArrowRightOutlined/>
            <Typography.Text className="diff-header-path">&lrm;{fileData.newPath}</Typography.Text>
        </span>
    } else {
        path = <Typography.Text className={"diff-header-path"}>
            &lrm;{!fileData.newPath || getRelevantPath(fileData)}
        </Typography.Text>
    }

    let badge;
    if (renamed) {
        badge = <span className="diff-header-badge diff-header-rename-badge">Renamed</span>
    } else if (fileData.type === "delete") {
        badge = <span className="diff-header-badge diff-header-delete-badge">Deleted file</span>
    } else if (fileData.type === "add") {
        badge = <span className="diff-header-badge diff-header-add-badge">New file</span>
    }

    return (
        <span className="header-summary">
            {path}
            {badge}
        </span>
    );
}

const ChangeSetSummmary: React.FC<{ changeSet: ChangeSet }> = ({changeSet}) => {
    const [added, removed] = countChangeSetAddedAndRemovedLines(changeSet);
    const numberOfChanges = changeSet.changes?.length || 0;
    const filesSuffix = numberOfChanges === 1 ? "file" : "files";

    return (
        <div className="change-set-stats">
            {changeSet.changes?.length > 0 &&<Typography.Text className="header-summary-files">
                {changeSet.changes.length} {filesSuffix}
            </Typography.Text>}
            <Typography.Text className="header-summary-lines header-summary-add">
                +{added}
            </Typography.Text>
            <Typography.Text className="header-summary-lines header-summary-delete">
                -{removed}
            </Typography.Text>
        </div>
    )
}

const HeaderLines: React.FC<{ fileInfo: FileInfo }> = ({fileInfo}) => {
    let added = 0;
    let removed = 0;
    fileInfo.fileData.hunks.forEach((hunk: HunkData) => {
        const [hunkAdded, hunkRemoved] = countHunkAddedAndRemovedLines(hunk);
        added += hunkAdded;
        removed += hunkRemoved;
    })

    return (
        <span className="header-summary">
            <Typography.Text className={"header-summary-lines header-summary-add"}>
                +{added}
            </Typography.Text>
            <Typography.Text className={"header-summary-lines header-summary-delete"}>
                -{removed}
            </Typography.Text>
            <Button
                className="copy-button"
                icon={<CopyOutlined />}
                onClick={(e) => {
                    navigator.clipboard.writeText(fileInfo.rawPatch)
                    e.stopPropagation()
                }}
            />
        </span>
    );
}

const HunkHeader: React.FC<{hunk: HunkData, onClick?: (e: React.MouseEvent) => void}> = ({hunk, onClick}) => {
    return (
        <Decoration
            key={`${hunk.content}-header`}
            contentClassName={"diff-code-header"}
            gutterClassName={"diff-gutter-header"}
        >
            {
                !onClick ? <span></span> :
                    <span className="diff-expander" onClick={onClick}>
                        <ArrowUpOutlined/>
                    </span>
            }
            <span>{hunk.content}</span>
        </Decoration>
    )
}

const HunkFooter: React.FC<{hunk: HunkData, onClick?: (e: React.MouseEvent) => void | undefined}> = ({hunk, onClick}) => {
    return (
        <Decoration
            key={`${hunk.content}-footer`}
            contentClassName={"diff-code-footer"}
            gutterClassName={"diff-gutter-footer"}
        >
            {
                !onClick ? <span></span> :
                    <span className="diff-expander" onClick={onClick}>
                        <ArrowDownOutlined/>
                    </span>
            }
            <span></span>
        </Decoration>
    )
}

type TokenizeOptions = {
    highlight: boolean;
    refractor: typeof refractor;
    oldSource?: string;
    language: string;
    enhancers: TokenizeEnhancer[];
};

const highlightSyntax = (hunks: HunkData[], preimage: Source | null, filename: string) => {
    if (!hunks) {
        return undefined;
    }

    const pathSegments = filename.split('/');
    const fileName = pathSegments.pop() || '';
    const extension: string = fileName.includes('.') ? fileName.split('.').pop() || 'text' : 'text';

    const options: TokenizeOptions = {
        highlight: true,
        refractor: refractor,
        oldSource: preimage ? preimage as string : undefined,
        language: extension,
        enhancers: [markEdits(hunks, { type: 'block' })],
    };

    try {
        return tokenize(hunks, options);
    } catch (ex) {
        return undefined;
    }
}

const getRelevantPath = (file: FileData) => {
    if (file.type === "delete") return file.oldPath;
    if (fileWasRenamed(file.type, file.similarity)) return file.oldPath;

    return file.newPath;
}

const fileWasRenamed = (type: string, similarity: number | undefined) => {
    // When similarity is set, gitdiff-parser sets type to "modify", so we need
    // to check similarity first. 50% is the default threshold used by git:
    // https://git-scm.com/docs/git-diff#Documentation/git-diff.txt--Mltngt
    if (similarity !== undefined && similarity > 50) return true;

    return type === "rename" || type === "copy";
}

export type FileInfo = {
    fileData: FileData,
    source: Source | null,
    fileLines: number,
    change_ids: string[],
    rawPatch: string,
}

export type HunkHighlight = {
    startChange: ChangeData,
    endChange: ChangeData,
    revertHunkFn: () => void,
    revertHunkDisabled: boolean,
}

const changeSetToFileInfos = (changeSet: ChangeSet): FileInfo[] => {
    return toFileInfos(changeSet.changes, changeSet.preimages)
}

const toFileInfos = (changes: ChangedFile[], preimages: FileImage[]): FileInfo[] => {
    if (!changes) {
        return []
    }

    const preimageMap = new Map<string, FileImage>(
        preimages?.map((image: FileImage) => [image.file_path, image])
    )

    return changes.map((change: ChangedFile) => {
        const patch = parseDiff(change.patch).find(Boolean);
        if (!patch) {
            throw new Error("Invalid patch")
        }

        const preimage = preimageMap.get(getRelevantPath(patch));
        return {
            fileData: patch,
            source: preimage ? preimage.contents : null,
            fileLines: preimage && preimage.contents ? preimage.contents.split("\n").length : 0,
            change_ids: change.change_ids,
            rawPatch: change.patch
        }
    })
}

const countChangeSetAddedAndRemovedLines = (changeSet: ChangeSet): number[] => {
    let added = 0;
    let removed = 0;
    if (!changeSet.changes) {
        return [added, removed]
    }

    changeSet.file_infos.forEach((fileInfo: FileInfo) => {
        fileInfo.fileData.hunks.forEach((hunk: HunkData) => {
            const [hunkAdded, hunkRemoved] = countHunkAddedAndRemovedLines(hunk);
            added += hunkAdded;
            removed += hunkRemoved;
        })
    });

    return [added, removed]
}

const countHunkAddedAndRemovedLines = (hunk: HunkData): number[] => {
    let added = 0;
    let removed = 0;
    hunk.changes.forEach((change: ChangeData) => {
        if (change.type === "delete") removed++;
        if (change.type === "insert") added++;
    })
    return [added, removed]
}

const splitHighlightedHunk = (hunk: HunkData, highlights: HunkHighlight[]): JSX.Element[] => {
    const changeKeys: string[] = hunk.changes.map((change: ChangeData) => getChangeKey(change));
    return highlights.flatMap((highlight: HunkHighlight, idx, arr) => {
        const highlightStartKey = getChangeKey(highlight.startChange);
        const highlightEndKey = getChangeKey(highlight.endChange);

        if (!changeKeys.includes(highlightStartKey) || !changeKeys.includes(highlightEndKey)) {
            // If we didn't find the highlight in the hunk don't render anything -- we'll pick it up in the next pass through
            return []
        }

        // The start of this hunk is the start of the highlight
        const startIdx = changeKeys.indexOf(highlightStartKey);
        const endIdx = changeKeys.indexOf(highlightEndKey) + 1;

        // The end of this hunk is the beginning of the next highlight if and only if it both exists and is in this hunk.
        // Otherwise, the end of this hunk is the end of this hunk.
        const next = idx < arr.length - 1 ? arr[idx + 1] : null;
        const hunkEndIdx = next && changeKeys.includes(getChangeKey(next.startChange)) ?
            changeKeys.indexOf(getChangeKey(next.startChange)): hunk.changes.length;

        const prevHighlight = idx > 0 ? arr[idx - 1] : null;

        const elements = []
        // If there are changes before the highlight pop them off and render them separately if they have not already
        // been rendered by an above highlight
        if (startIdx > 0 && (prevHighlight === null || !changeKeys.includes(getChangeKey(prevHighlight.endChange)))) {
            elements.push(
                <Hunk
                    key={`above-hunk-${highlightStartKey}-${highlightEndKey}`}
                    hunk={{...hunk, changes: hunk.changes.slice(0, startIdx)}}
                />
            )
        }

        // Next render...
        // 1. A checkbox decoration that ties the following hunk to the proposal it represents.
        // 2. The hunk for the proposal.
        // 3. A closing footer to draw visual distinction between the proposal and the rest of the code.
        // 4. The rest of the code.
        elements.push(
            <Decoration
                key={`above-highlight-${highlightStartKey}-${highlightEndKey}`}
                contentClassName={"above-highlight-code"}
                gutterClassName={"above-highlight-gutter"}
            >
                <span />
                <Tooltip title="Revert this change" placement="left" arrow={false}>
                    <Button
                        className="above-highlight-button"
                        icon={<CloseOutlined style={{fontSize: "12px"}} className="above-highlight-icon"/>}
                        onClick={() => highlight.revertHunkFn()}
                        disabled={highlight.revertHunkDisabled}
                    />
                </Tooltip>
            </Decoration>,
            <Hunk
                key={`highlight-${highlightStartKey}-${highlightEndKey}`}
                hunk={{...hunk, changes: hunk.changes.slice(startIdx, endIdx)}}
            />,
            <Decoration
                key={`below-highlight-${highlightStartKey}-${highlightEndKey}`}
                contentClassName={"below-highlight-code"}
                gutterClassName={"below-highlight-gutter"}
            >
                <></>
            </Decoration>,
            <Hunk
                key={`below-hunk-${highlightStartKey}-${highlightEndKey}`}
                hunk={{...hunk, changes: hunk.changes.slice(endIdx, hunkEndIdx)}}
            />
        );

        return elements
    })
}

const renderHunk = (
    hunk: HunkData,
    idx: number,
    hunks: HunkData[] | null,
    highlights: HunkHighlight[] | null,
    fileLines: number,
    expandCodeFn?: (start: number, end: number) => void
) => {
    const aboveHunk: HunkData | null = hunks && idx > 0 ? hunks[idx - 1] : null
    const belowHunk: HunkData | null = hunks && idx < hunks.length - 1 ? hunks[idx + 1] : null
    const expandUpStart = Math.max(
        aboveHunk ? aboveHunk.oldStart + aboveHunk.oldLines : 1,
        hunk.oldStart - EXPAND_LINES,
        1
    )
    const expandDownEnd = Math.min(
        belowHunk ? belowHunk.oldStart : fileLines,
        hunk.oldStart + hunk.oldLines + EXPAND_LINES,
        fileLines
    )

    const elements = []
    if (aboveHunk || hunk.oldStart !== 1) {
        elements.push(
            <HunkHeader
                key={`${hunk.content}-header`}
                hunk={hunk}
                onClick={expandCodeFn && fileLines > 0 ? (e: React.MouseEvent) => {
                    expandCodeFn(expandUpStart, hunk.oldStart)
                    e.stopPropagation()
                }: undefined}
            />);
    }

    if (highlights) {
        splitHighlightedHunk(hunk, highlights).forEach((element: JSX.Element) => elements.push(element))
    } else {
        elements.push(<Hunk key={hunk.content} hunk={hunk}/>);
    }

    if (belowHunk || hunk.oldStart + hunk.oldLines < fileLines) {
        elements.push(
            <HunkFooter
                key={`${hunk.content}-footer`}
                hunk={hunk}
                onClick={expandCodeFn && fileLines > 0 ? (e: React.MouseEvent) => {
                    expandCodeFn(hunk.oldStart + hunk.oldLines, expandDownEnd)
                    e.stopPropagation()
                }: undefined}
            />
        );
    }
    return elements;
}

export {
    ChangeSetSummmary,
    DiffHeader,
    HeaderLines,
    HunkHeader,
    HunkFooter,
    highlightSyntax,
    getRelevantPath,
    fileWasRenamed,
    renderHunk,
    changeSetToFileInfos,
    toFileInfos,
    countChangeSetAddedAndRemovedLines,
}
