"use client";
import React, {useState} from "react";
import { sendSessionReport } from "../data/SolverSession";
import {Button, Divider, Form, Input, Modal, Typography} from "antd";
import {NotificationInstance} from "antd/lib/notification/interface";

import TraceableNotificationDescription from "./TraceableNotificationDescription";

interface ReportModalProps extends React.HTMLAttributes<HTMLDivElement> {
    session_id: string
    modalOpen: boolean
    onOpenChange: (open: boolean) => void
    notification: NotificationInstance
}

const ReportModal: React.FC<ReportModalProps> = ({session_id, modalOpen, onOpenChange, notification}) => {
    const [sendingReport, setSendingReport] = useState<boolean>(false);

    const [form] = Form.useForm();
    const descriptionValue = Form.useWatch("description", form);

    const onSendReport = () => {
        setSendingReport(true);

        sendSessionReport(session_id, descriptionValue)
            .then(status => {
                if (status === 200) {
                    onOpenChange(false)
                    form.resetFields();
                    notification.success({
                        message: "Report sent",
                        description: "Your report has been sent. Thank you for helping us improve!",
                        placement: "bottomRight",
                    })
                } else {
                    notification.error({
                        message: "Report failed to send",
                        description: <TraceableNotificationDescription
                            description="Your report failed to send. Please try again later."
                            session_id={session_id}
                        />,
                        placement: "bottomRight",
                    })
                }
            })
            .finally(() => setSendingReport(false))
    }

    const disableSendButton = () => {
        return !descriptionValue
    }

    return (
        <Modal
            open={modalOpen}
            onCancel={() => onOpenChange(false)}
            footer={null}
        >
            <Typography.Title level={5} className="settings-title">Report an issue</Typography.Title>
            <Divider type="horizontal"/>
            <Form form={form} layout="vertical">
                <Form.Item
                    name="description"
                    label={null}
                    rules={[{required: true, message: "Please provide a description"}]}
                >
                    <Input.TextArea rows={6} placeholder="Describe the issue" maxLength={500} showCount={true}/>
                </Form.Item>

                <Form.Item>
                    <div className="send-report-form-submit">
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={onSendReport}
                            disabled={disableSendButton()}
                            loading={sendingReport}
                        >Send</Button>
                        <p className="send-report-disclaimer">
                            As part of reviewing this report, we will be able to see code and filenames scanned while solving issues during this session.
                        </p>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ReportModal;
