"use client";
import React from "react";
import {useSolverInterfaceContext} from "../data/SolverInterface";
import {Button, Col, Dropdown, Image, Row, Space} from "antd";
import {CaretDownOutlined, LoadingOutlined, SettingOutlined} from "@ant-design/icons";
import "../index.css"
import RepoCard from "./RepoCard";
import SettingsPopover from "./SettingsPopover";
import solverLogo from "../images/solver_logo_reverse_transparent.png"
import {useLoadSession} from "../data/SolverSession";

const SolverHeader: React.FC = () => {
    const loadSession = useLoadSession()
    const {loggedIn, repos, activeRepo, loadingRepos, setActiveRepo} = useSolverInterfaceContext();

    const [settingsOpen, setSettingsOpen] = React.useState<boolean>(false);

    const buildHeaderActions = () => {
        if (!loggedIn) return undefined

        return (
            <Row wrap={false} align="middle" className="header-action-bar">
                {buildDropdownButton()}
                {buildSettingsButton()}
            </Row>
        )
    }

    const reposExcludingActive = () => {
        if (repos === undefined) return []

        return repos.filter(repo => repo !== activeRepo)
    }

    const haveAdditionalRepos = () => {
        return reposExcludingActive().length > 0
    }

    const repoDropdownClassName = () => {
        if (haveAdditionalRepos()) {
            return "repo-dropdown"
        } else {
            return "repo-dropdown repo-dropdown-empty"
        }
    }

    const buildDropdownButton = () => {
        return (
            <Dropdown
                disabled={!loggedIn || loadingRepos || !haveAdditionalRepos()}
                menu={{
                    items: reposExcludingActive().map((repo, index) => {
                        return {
                            label: `${repo.org} / ${repo.name}`,
                            key: index,
                            onClick: () => {
                                setActiveRepo(repo)
                                loadSession(undefined)
                            }
                        }
                    }),
                }}
                // TODO: Small visual issue: LAR-854
                // Scrolling to the bottom removes the top border of the dropdown
                // and scrolling to the top removes the bottom border.
                overlayClassName="repo-dropdown-overlay"
                placement={"bottomLeft"}
                className={repoDropdownClassName()}
                trigger={["click"]}
            >
                <Space align="center">
                    {haveAdditionalRepos() && <CaretDownOutlined/>}
                    {dropdownButtonContent()}
                </Space>
            </Dropdown>
        )
    }

    const dropdownButtonContent = () => {
        if (!loggedIn) {
            return undefined
        } else if (loadingRepos) {
            return <LoadingOutlined className="loading-repos-icon"/>
        } else if (activeRepo) {
            return <RepoCard repo={activeRepo}/>
        } else if (repos.length === 0) {
            return "No Repos Found"
        }
    }

    const buildSettingsButton = () => {
        return (
            <SettingsPopover popoverOpen={settingsOpen} onOpenChange={setSettingsOpen}>
                <Space>
                    <Button
                        className="settings-btn"
                        onClick={() => setSettingsOpen(!settingsOpen)}
                        disabled={!loggedIn}
                    >
                        <SettingOutlined/>
                    </Button>
                </Space>
            </SettingsPopover>
        )
    }

    return (
        <>
            <Row wrap={false} align="middle" className="session-header">
                <Col flex="auto">
                    <Image
                        className="solver-logo"
                        // 6:1 aspect ratio
                        width={324}
                        height={54}
                        preview={false}
                        src={solverLogo}
                        alt={"Solver logo"}
                        onClick={() => {
                            loadSession(undefined)
                        }}
                    />
                </Col>
                <Col flex="none">
                    {buildHeaderActions()}
                </Col>
            </Row>
        </>
    )
}

export default SolverHeader;
