"use client";
import { FilterOutlined, LoadingOutlined, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Divider, Input, List, Popover, Space, Tooltip } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import React, { useEffect } from "react";
import { SessionSortAttribute, SessionSortOrder, SessionStatusFilter, useSessionBrowsingContext } from "../data/SessionBrowsing";
import { SessionStub, SolverSession } from "../data/SolverSession";
import { useDebounce } from "../hooks/useDebounce";

import AvatarVariantFactory from "../data/AvatarVariantFactory";
import "../index.css";
import FilterList, { FilterListItem } from "./FilterList";
import SessionCard from "./SessionCard";

import { Repo } from "../data/Repos";
import { User } from "../data/User";

enum FilterMenuState {
    GENERAL = "General",
    STATUS = "Status",
    AUTHOR = "Author",
    SORT = "Sort",
}

const generalFilterListItems: FilterListItem[] =
    Object.values(FilterMenuState)
        .filter((state) => state !== FilterMenuState.GENERAL)
        .map((state) => {
            return { key: state, label: state }
        })

const sessionStatusFilterListItems: FilterListItem[] =
    Object.values(SessionStatusFilter).map((status) => {
        return { key: status, label: status }
    })

enum SessionSortMenuKey {
    LAST_MODIFIED_DESCENDING = "Last modified ↓",
    LAST_MODIFIED_ASCENDING = "Last modified ↑",
    CREADTED_DESCENDING = "Created time ↓",
    CREATED_ASCENDING = "Created time ↑",
}

const sessionSortMenuKeyToAttributeAndOrder = (menuKey: SessionSortMenuKey) => {
    switch (menuKey) {
        case SessionSortMenuKey.LAST_MODIFIED_ASCENDING:
            return [SessionSortAttribute.LAST_MODIFIED, SessionSortOrder.ASCENDING]
        case SessionSortMenuKey.LAST_MODIFIED_DESCENDING:
            return [SessionSortAttribute.LAST_MODIFIED, SessionSortOrder.DESCENDING]
        case SessionSortMenuKey.CREATED_ASCENDING:
            return [SessionSortAttribute.CREATED, SessionSortOrder.ASCENDING]
        case SessionSortMenuKey.CREADTED_DESCENDING:
            return [SessionSortAttribute.CREATED, SessionSortOrder.DESCENDING]
    }
}

const attributeAndOrderToSessionSortMenuKey = (attribute: SessionSortAttribute, order: SessionSortOrder) => {
    switch (attribute) {
        case SessionSortAttribute.LAST_MODIFIED:
            switch (order) {
                case SessionSortOrder.ASCENDING:
                    return SessionSortMenuKey.LAST_MODIFIED_ASCENDING
                case SessionSortOrder.DESCENDING:
                    return SessionSortMenuKey.LAST_MODIFIED_DESCENDING
            }
            break;
        case SessionSortAttribute.CREATED:
            switch (order) {
                case SessionSortOrder.ASCENDING:
                    return SessionSortMenuKey.CREATED_ASCENDING
                case SessionSortOrder.DESCENDING:
                    return SessionSortMenuKey.CREADTED_DESCENDING
            }
    }
}

const sessionSortFilterListItems: FilterListItem[] =
    Object.values(SessionSortMenuKey).map((menuKey) => {
        return { key: menuKey, label: menuKey }
    })

const SessionsList: React.FC<{
    currentUser: User | undefined,
    repo: Repo,
    activeSession: SolverSession | undefined,
    onNewSession: (branch: string) => Promise<void>,
    onSwitchSession: (session: SessionStub) => Promise<void>,
    onUpdateTitle: (session: SessionStub, newName: string) => Promise<boolean>,
    onDeleteSession: (session: SessionStub) => void,
    notification: NotificationInstance,
}> = ({
    currentUser,
    repo,
    activeSession,
    onNewSession,
    onSwitchSession,
    onUpdateTitle,
    onDeleteSession,
    notification
}) => {
    const [createInProgress, setCreateInProgress] = React.useState<boolean>(false);
    const [branchSearchText, setBranchSearchText] = React.useState<string>("");
    const [filterMenuState, setFilterMenuState] = React.useState<FilterMenuState>(FilterMenuState.GENERAL);

    const {
        sessions,
        loadingSessions,
        authors,
        loadingAuthors,
        titleFilter,
        setTitleFilter,
        statusFilter,
        setStatusFilter,
        authorFilters,
        addAuthorFilter,
        removeAuthorFilter,
        sortAttribute,
        setSortAttribute,
        sortOrder,
        setSortOrder,
        loadSessions
    } = useSessionBrowsingContext();

    // TODO: after figuring out if we want a list or table view, submit
    // paginated requests to load more session.
    const debouncedLoadSessions = useDebounce(() => {
        loadSessions(repo.org, repo.name, 0, 20)
    }, 1000)

    useEffect(() => {
        setBranchSearchText("");
    }, [repo])

    const createNewSession = async () => {
        if (createInProgress) return;
        if (!repo.defaultBranch && branchSearchText === "") return;

        const selectedBranch = branchSearchText === "" ? repo.defaultBranch : branchSearchText;
        if (!selectedBranch) return;

        if (!repo.branches.includes(selectedBranch)) {
            notification.error({
                placement: "bottomRight",
                message: "Branch not found",
                description: `Branch ${branchSearchText} not found in ${repo.org}/${repo.name}.`
            })
            return;
        }

        setCreateInProgress(true);
        await onNewSession(selectedBranch);
        setBranchSearchText("");
        setCreateInProgress(false);
    }

    const buildNewSessionButton = () => {
        const newSessionTooltipText = repo.branches.length > 0 ?
            "Create new Session" :
            "No branches were found in this repo, please create a branch to create a Session"

        return (
            <Space className="new-session-form">
                <AutoComplete
                    value={branchSearchText}
                    onChange={setBranchSearchText}
                    className="new-session-branch-input"
                    popupMatchSelectWidth={300}
                    placeholder={repo.defaultBranch || "No branches found"}
                    onSelect={(value) => {
                        setBranchSearchText(value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            createNewSession();
                        }
                    }}
                    disabled={repo.branches.length === 0}
                    options={repo.branches.map((branch) => {
                        return { value: branch, label: branch }
                    })}
                    filterOption={(inputValue, option) =>
                        option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                />
                <Tooltip title={newSessionTooltipText} placement="right" arrow={false}>
                    <Button
                        loading={createInProgress}
                        onClick={() => {
                            createNewSession();
                        }}
                        icon={<PlusCircleOutlined/>}
                    />
                </Tooltip>
            </Space>
        );
    }

    const buildList = () => {
        if (loadingSessions) {
            return <div style={{textAlign: "center"}}><LoadingOutlined/></div>
        } else if (sessions.length === 0) {
            return <div style={{textAlign: "center"}}>No sessions found</div>
        }

        return (
            <List
                itemLayout="vertical"
                size="small"
                split={false}
                pagination={{position: "bottom", align: "center", pageSize: 7, size: "small"}}
                dataSource={sessions}
                renderItem={(item: SessionStub) => {
                    return (
                        <List.Item className="session-list-item" key={item.session_id} onClick={(e) => {
                            if (document.getSelection()?.toString() !== "") return;

                            onSwitchSession(item)
                            e.preventDefault()
                        }}>
                            <SessionCard
                                session={item}
                                selected={activeSession?.session_id === item.session_id}
                                notification={notification}
                                onUpdateTitle={newName => onUpdateTitle(item, newName)}
                                onDelete={() => onDeleteSession(item)}
                                editable={currentUser?.id === item.user_id}
                            />
                        </List.Item>
                    )
                }}
            />
        );
    }

    const buildFilterList = () => {
        return <div className="session-filter-list">
            {buildFilterListContent()}
        </div>
    }

    const buildFilterListContent = () => {
        switch (filterMenuState) {
            case FilterMenuState.GENERAL:
                return <FilterList
                    items={generalFilterListItems}
                    selectedKeys={[FilterMenuState.GENERAL]}
                    onSelect={(key) => {
                        setFilterMenuState(key as FilterMenuState)
                    }}
                    selectable={false}
                />
            case FilterMenuState.STATUS:
                return <FilterList
                    items={sessionStatusFilterListItems}
                    selectedKeys={[statusFilter]}
                    onSelect={(key) => {
                        setStatusFilter(key as SessionStatusFilter)

                        debouncedLoadSessions();
                    }}
                />
            case FilterMenuState.AUTHOR:
                if (loadingAuthors) return <div style={{textAlign: "center"}}><LoadingOutlined/></div>
                else if (authors.length === 0) return <div style={{textAlign: "center"}}>No authors found</div>
                else {
                    const filterListItems: FilterListItem[] = authors.map(user => {
                        const avatarUrl = AvatarVariantFactory.createURLVariant(user.avatar_url, user.auth_type, 40)

                        return {
                            key: user.id,
                            iconUrl: avatarUrl,
                            label: user.name,
                        }
                    })

                    return <FilterList
                        items={filterListItems}
                        selectedKeys={authorFilters}
                        onSelect={(key) => {
                            addAuthorFilter(key)

                            debouncedLoadSessions();
                        }}
                        onDeselect={(key) => {
                            removeAuthorFilter(key)

                            debouncedLoadSessions();
                        }}
                    />
                }
            case FilterMenuState.SORT:
                return <FilterList
                    items={sessionSortFilterListItems}
                    selectedKeys={[attributeAndOrderToSessionSortMenuKey(sortAttribute, sortOrder)]}
                    onSelect={(key) => {
                        const [attribute, order] = sessionSortMenuKeyToAttributeAndOrder(key as SessionSortMenuKey)

                        setSortAttribute(attribute as SessionSortAttribute)
                        setSortOrder(order as SessionSortOrder)

                        debouncedLoadSessions();
                    }}
                />
        }
    }

    return (
        <>
            {buildNewSessionButton()}
            <Divider type={"horizontal"} className="sider-divider"/>
            <div className="session-list-search">
                <Input
                    value={titleFilter}
                    onChange={e => {
                        setTitleFilter(e.target.value)

                        debouncedLoadSessions();
                    }}
                    placeholder="Search sessions"
                    addonBefore={<SearchOutlined/>}
                    addonAfter={
                        <Popover
                            arrow={false}
                            trigger={"click"}
                            content={buildFilterList()}
                            onOpenChange={() => {
                                setFilterMenuState(FilterMenuState.GENERAL)
                            }}
                            placement="bottomRight"
                        >
                            <Tooltip title="Filter" arrow={false} placement="right">
                                <Button type="text" size="small" icon={<FilterOutlined />} />
                            </Tooltip>
                        </Popover>
                    }
                    allowClear
                />
            </div>
            {buildList()}
        </>
    )
}

export default SessionsList;
