"use client";
import { Input } from "antd";
import { TextAreaRef } from "antd/es/input/TextArea";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';

interface DetailEditorProps {
    value: string | undefined,
    onChange?: (value: string | undefined) => void,
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void,
    placeholder?: string,
}

export interface FocusableRef {
    focus: () => void;
}

const DetailEditor = forwardRef<FocusableRef, DetailEditorProps>(({
    value,
    onChange,
    onKeyDown,
    placeholder,
}, ref) => {

    const textareaRef = useRef<TextAreaRef | null>(null);
    useImperativeHandle(ref, () => {
        return {
            focus: () => {
                setEditMode();
            }
        }
    }, []);

    const [textMode, setTextMode] = React.useState<"edit" | "preview">("preview");

    const getTextArea = () => {
        if (!textareaRef.current) return null;

        return textareaRef.current?.resizableTextArea?.textArea;
    }

    const setEditMode = () => {
        setTextMode("edit");

        const textArea = getTextArea();
        if (!textArea) return;

        // Needs to be visible to set focus.
        textArea.style.display = "block";
        textArea.focus();
    }

    const buildMarkdownPreview = () => {
        return (
            <Markdown
                remarkPlugins={[remarkGfm]}
                components={{
                    p: ({ className, children, ...props }) => {
                        return <p className={`${className ? className : ""} markdown-preview-paragraph`} {...props}>{children}</p>
                    },
                    code: ({ className, children, ...props }) => {
                        return <code className={`${className ? className : ""} markdown-preview-code`} {...props}>{children}</code>
                    },
                    pre: ({ className, children, ...props }) => {
                        return <pre className={`${className ? className : ""} markdown-preview-pre`} {...props}>{children}</pre>
                    },
                    table: ({ className, children, ...props }) => {
                        return <table className={`${className ? className : ""} markdown-preview-table`} {...props}>{children}</table>
                    },
                    thead: ({ className, children, ...props }) => {
                        return <thead className={`${className ? className : ""} markdown-preview-thead`} {...props}>{children}</thead>
                    },
                    tbody: ({ className, children, ...props }) => {
                        return <tbody className={`${className ? className : ""} markdown-preview-tbody`} {...props}>{children}</tbody>
                    },
                    tr: ({ className, children, ...props }) => {
                        return <tr className={`${className ? className : ""} markdown-preview-tr`} {...props}>{children}</tr>
                    },
                    th: ({ className, children, ...props }) => {
                        return <th className={`${className ? className : ""} markdown-preview-th`} {...props}>{children}</th>
                    },
                    td: ({ className, children, ...props }) => {
                        return <td className={`${className ? className : ""} markdown-preview-td`} {...props}>{children}</td>
                    },
                    img: ({ className, src, alt, ...props }) => {
                        return <img className={`${className ? className : ""} markdown-preview-img`} src={src} alt={alt} {...props} />
                    }
                }}
            >
                {value}
            </Markdown>
        )
    }

    return (
        <>
            <Input.TextArea
                style={{ display: textMode === "edit" ? "block" : "none" }}
                ref={textareaRef}
                value={value}
                onChange={(e) => {
                    if (onChange) onChange(e.target.value);
                }}
                onBlur={() => {
                    setTextMode("preview");
                }}
                placeholder={placeholder}
                autoSize={{ minRows: 1, maxRows: 12 }}
                className="nl-text-input"
                onFocus={e => {
                    const textLength = e.target.value.length;
                    e.target.setSelectionRange(textLength, textLength);
                }}
                onKeyDown={(e) => {
                    if (onKeyDown) onKeyDown(e);
                }}
            />
            <div
                className="markdown-preview"
                style={{ display: textMode === "preview" ? "block" : "none" }}
                onClick={() => setEditMode()}
            >
                {!value
                    ? <div className="markdown-preview-placeholder">{placeholder}</div>
                    : buildMarkdownPreview()
                }
            </div>
        </>
    )
})

DetailEditor.displayName = "DetailEditor";

export default DetailEditor;
