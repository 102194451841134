import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    ContainerOutlined,
    CopyOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    EllipsisOutlined,
    ExclamationCircleOutlined,
    ExportOutlined,
    FlagOutlined,
    LoadingOutlined,
    PullRequestOutlined
} from "@ant-design/icons";
import { Card, Dropdown, Input, MenuProps, Modal, Tooltip, Typography } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import classNames from 'classnames';
import { formatDistanceToNow } from "date-fns";
import React, { useEffect } from 'react';

import AvatarVariantFactory from "../data/AvatarVariantFactory";
import {
    createPullRequest,
    getPatchContents,
    SessionStatus,
    SessionStub,
} from "../data/SolverSession";
import { pullRequestUrl, revisionUrl } from "../data/User";
import ReportModal from "./ReportModal";
import TraceableNotificationDescription from "./TraceableNotificationDescription";

import { ModalStaticFunctions } from "antd/es/modal/confirm";
import "./SessionCard.css";

const SessionCard: React.FC<{
    session: SessionStub,
    selected: boolean,
    notification: NotificationInstance,
    onUpdateTitle: (newTitle: string) => Promise<boolean>,
    onDelete: () => void,
    editable: boolean,
}> = ({ session, selected, notification, onUpdateTitle, onDelete, editable }) => {
    const [title, setTitle] = React.useState<string>(session.title);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [reportModalOpen, setReportModalOpen] = React.useState<boolean>(false);
    const [timeAgoMessage, setTimeAgoMessage] = React.useState<string>(
        formatDistanceToNow(new Date(session.modify_timestamp * 1000), { addSuffix: true })
    );

    const [updatingTitle, setUpdatingTitle] = React.useState<boolean>(false);

    useEffect(() => {
        setTitle(session.title)
    }, [session.title])

    useEffect(() => {
        const interval = setInterval(
            () => setTimeAgoMessage(formatDistanceToNow(new Date(session.modify_timestamp * 1000), { addSuffix: true })),
            5000
        );
        return () => clearInterval(interval);
    }, [session.modify_timestamp]);

    const renameSession = async (newTitle: string) => {
        if (newTitle === session.title) return;

        const computedTitle = newTitle === "" ? "Untitled Session" : newTitle;

        setUpdatingTitle(true);
        const success = await onUpdateTitle(computedTitle);
        setUpdatingTitle(false);

        setTitle(success ? computedTitle : session.title);
    }

    const [modal, modalContextHolder] = Modal.useModal();
    const onExportDropdownClick: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case "file":
                exportToFile(session.session_id, notification);
                break;
            case "clipboard":
                exportToClipboard(session.session_id, notification);
                break;
            case "pr":
                notifyPullRequestCreation(session, notification, modal);
                break;
        }
    }

    const onReportDropdownClick: MenuProps['onClick'] = ({ key }) => {
        if (key === "report") {
            setReportModalOpen(true);
        } else if (key === "copy") {
            navigator.clipboard.writeText(session.session_id)
                .then(() => notification.success({
                    message: "Copied session ID",
                    placement: "bottomRight",
                    duration: 3,
                }))
                .catch(() => notification.error({
                    message: "Copy failed",
                    description: "Could not copy Session ID to clipboard.",
                    placement: "bottomRight",
                    duration: 3,
                }))
        }
    }

    const getStatusIcon = () => {
        switch (session.status) {
            case SessionStatus.READY:
                return <CheckCircleOutlined
                    className="session-status-icon"
                />;
            case SessionStatus.ARCHIVED:
                return <ContainerOutlined
                    className="session-status-icon"
                />;
            case SessionStatus.PENDING:
                return <ClockCircleOutlined
                    className="session-status-icon"
                />;
            case SessionStatus.SOLVING:
            case SessionStatus.SUBMITTING_CANCEL:
            case SessionStatus.SUBMITTING_SOLVE:
                return <LoadingOutlined
                    className="session-status-icon session-status-icon-loading"
                />;
            default:
                return <ExclamationCircleOutlined
                    className="session-status-icon"
                />;
        }
    }

    const getStatusTooltip = () => {
        switch (session.status) {
            case SessionStatus.READY:
                return "Ready to solve";
            case SessionStatus.PENDING:
                return "Pending";
            case SessionStatus.SUBMITTING_SOLVE:
                return "Submitting";
            case SessionStatus.SOLVING:
                return "Solving";
            case SessionStatus.SUBMITTING_CANCEL:
                return "Cancelling";
            case SessionStatus.ARCHIVED:
                return "Archived";
            default:
                return "Unknown status";
        }
    }

    const buildTitle = () => {
        const titleTextclass = classNames({
            "session-title-text-unselected": !selected,
            "session-title-text-selected": selected
        });

        if (selected && editable) {
            if (editing) {
                return (
                    <Input
                        value={title}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === "Escape") {
                                const value = (e.target as HTMLInputElement).value

                                setEditing(false)
                                renameSession(value)
                            }
                        }}
                        onChange={value => setTitle(value.target.value)}
                        autoFocus={true}
                        onBlur={() => {
                            setEditing(false)
                            renameSession(title)
                        }}
                    />
                )
            }

            return (
                <div className="session-title-edit" onClick={(e) => {
                    if (!editable) return;

                    setEditing(true)
                    e.stopPropagation();
                }}>
                    <Tooltip title={title} placement="top" arrow={false}>
                        <Typography.Text
                            onClick={(e) => {
                                setEditing(true)
                                e.stopPropagation();
                            }}
                            className={titleTextclass}
                            ellipsis={true}
                        >
                            {title}
                        </Typography.Text>
                    </Tooltip>
                    <Tooltip title="Edit title" placement="right" arrow={false}>
                        {updatingTitle ? <LoadingOutlined /> : <EditOutlined />}
                    </Tooltip>
                </div>
            )
        } else {
            return (
                <Tooltip title={title} placement="top" arrow={false}>
                    <Typography.Text className={titleTextclass}>
                        {title}
                    </Typography.Text>
                </Tooltip>
            )
        }
    }

    const buildActionButtons = () => {
        if (selected) {
            return [
                <Dropdown
                    key={1}
                    arrow={false}
                    menu={{
                        items: [
                            { icon: <DownloadOutlined />, label: 'Download patch file', key: 'file' },
                            { icon: <CopyOutlined />, label: 'Copy patch to clipboard', key: 'clipboard' },
                            { icon: <PullRequestOutlined />, label: 'Open pull request', key: 'pr' }
                        ],
                        onClick: onExportDropdownClick
                    }}
                    placement={"bottomRight"}
                >
                    <ExportOutlined />
                </Dropdown>,
                <Dropdown
                    key={2}
                    arrow={false}
                    menu={{
                        items: [
                            { icon: <FlagOutlined />, label: 'Report an issue', key: 'report' },
                            { icon: <CopyOutlined />, label: 'Copy session ID', key: 'copy' }
                        ],
                        onClick: onReportDropdownClick
                    }}
                    placement="bottomRight"
                >
                    <EllipsisOutlined />
                </Dropdown>
            ]
        }

        return []
    }

    const buildCardDescription = () => {
        const avatarUrl = AvatarVariantFactory.createURLVariant(session.user_avatar_url, session.auth_type, 40);

        return (
            <div className="session-card-description">
                <span className="session-card-author">
                    <img className="session-card-author-icon" src={avatarUrl} alt={session.user_name} />
                    <small>{session.user_name}</small>
                </span>
                <small className="session-card-timestamp">Updated {timeAgoMessage}</small>
            </div>
        )
    }

    const cardClasses = classNames({
        "session-card": true,
        "session-card-selected": selected
    });

    return (
        <>
            <Card
                hoverable={true}
                size="small"
                actions={buildActionButtons()}
                className={cardClasses}
                title={<div className="session-card-title-area">
                    <div className="session-card-title">{buildTitle()}</div>
                    <Tooltip title={session.base_revision.slice(0, 7)} placement="right">
                        <small className="session-card-branch-name">
                            <a
                                onClick={e => e.stopPropagation()}
                                href={revisionUrl(session.auth_type, session.repo_name, session.base_revision)}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {session.branch_name}
                            </a>
                        </small>
                    </Tooltip>
                </div>
                }
            >
                <div className="session-card-body">
                    <Tooltip title={getStatusTooltip()} placement="right">{getStatusIcon()}</Tooltip>
                    {buildCardDescription()}
                    {editable && <Tooltip title="Delete" arrow={false} placement="right">
                        <div className="session-card-delete" onClick={(e) => {
                            if (!editable) return;

                            onDelete()
                            e.stopPropagation()
                        }}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip>}
                </div>
            </Card>
            {selected && <ReportModal
                session_id={session.session_id}
                modalOpen={reportModalOpen}
                onOpenChange={setReportModalOpen}
                notification={notification}
            />}
            {modalContextHolder}
        </>
    )

};

const exportToFile = (session_id: string, notification: NotificationInstance) => {
    getPatchContents(session_id)
        .then((patch: string) => {
            const url = window.URL.createObjectURL(new Blob([patch]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `session-${session_id}.patch`);
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        })
        .then(() => notification.success({
            message: "Export complete",
            description: "Downloaded changes.",
            placement: "bottomRight",
            duration: 5,
        }))
        .catch((err: unknown) => {
            if (err instanceof Error) console.error(err.message);

            notification.error({
                message: "Export failed",
                description: <TraceableNotificationDescription
                    description="Could not download changes."
                    session_id={session_id}
                />,
                placement: "bottomRight"
            })
        })
}

const exportToClipboard = (session_id: string, notification: NotificationInstance) => {
    getPatchContents(session_id)
        .then((patch: string) => navigator.clipboard.writeText(patch))
        .then(() => notification.success({
            message: "Export complete",
            description: "Copied changes to clipboard.",
            placement: "bottomRight",
            duration: 5,
        }))
        .catch((err: unknown) => {
            if (err instanceof Error) console.error(err.message);

            notification.error({
                message: "Export failed",
                description: <TraceableNotificationDescription
                    description="Could not download changes."
                    session_id={session_id}
                />,
                placement: "bottomRight"
            })
        })
}

const notifyPullRequestCreation = (
    session: SessionStub,
    notification: NotificationInstance,
    modal: Omit<ModalStaticFunctions, "warn">
) => {
    if (session.pull_request) {
        const prNumber = parseInt(session.pull_request);

        if (isNaN(prNumber)) {
            notification.error({
                message: "Failed to open PR",
                description: <TraceableNotificationDescription
                    description="PR number is set for this session but is not a number."
                    session_id={session.session_id}
                />,
                placement: "bottomRight"
            });
            return;
        }

        window.open(pullRequestUrl(session.auth_type, session.repo_name, prNumber), "_blank");
        return;
    }

    const creatingPRModal = modal.info({
        title: <div className="create-pr-modal-title">Creating pull request...</div>,
        content: <div className="create-pr-modal-loading-icon">
            <LoadingOutlined />
        </div>,
        icon: null,
        okButtonProps: { style: { display: 'none' } },
        maskClosable: true,
        onCancel: () => {
            creatingPRModal.destroy();
        },
    });

    createPullRequest(session.session_id)
        .then((pull_request: string) => {
            creatingPRModal.destroy();
            window.open(pull_request, "_blank");
        })
        .catch(() => {
            creatingPRModal.destroy();
            notification.error({
                message: "PR Creation Failed",
                description: <TraceableNotificationDescription
                    description="Could not open pull request."
                    session_id={session.session_id}
                />,
                placement: "bottomRight"
            });
        });
}

export default SessionCard;
