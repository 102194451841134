import React, { useEffect, useRef } from "react";
import {Button, Tooltip} from "antd";
import {NotificationInstance} from "antd/lib/notification/interface";
import {FunctionOutlined, LoadingOutlined} from "@ant-design/icons";
import DetailEditor, { FocusableRef } from "./DetailEditor";
import TraceableNotificationDescription from "./TraceableNotificationDescription";

import {
    useSession,
    useSessionNLDraftDetail,
    useSessionStatus,
    useSolve,
    useCanSolve,
    useCancelSolve,
    useCanCancelSolve,
    useCreateAndSolve,
    SessionStatus,
} from "../data/SolverSession";

import {useSolverInterfaceContext} from "../data/SolverInterface";

const NaturalLanguageInput:  React.FC<{notification: NotificationInstance}>= ({notification}) => {
    const session = useSession();
    const nlDraftDetail = useSessionNLDraftDetail();
    const sessionStatus = useSessionStatus();
    const solve = useSolve();
    const createAndSolve = useCreateAndSolve();
    const canSolve = useCanSolve();
    const cancelSolve = useCancelSolve();
    const canCancelSolve = useCanCancelSolve();

    const { currentUser, activeRepo } = useSolverInterfaceContext();

    const detailEditorRef = useRef<FocusableRef>(null);

    // Cache the title and detail so that they can be updated when we solve.
    const [cachedNLDraftDetail, setCachedNLDraftDetail] = React.useState<string>("");

    useEffect(() => {
        setCachedNLDraftDetail(nlDraftDetail);
    }, [nlDraftDetail])

    useEffect(() => {
        detailEditorRef.current?.focus();
    }, [session?.session_id])

    const onDetailChange = (value: string | undefined) => setCachedNLDraftDetail(value || '');

    const buildButton = () => {
        switch (sessionStatus) {
            case SessionStatus.READY:
                return buildSolveButton();
            case SessionStatus.SUBMITTING_SOLVE:
                return buildSubmittingSolveButton();
            case SessionStatus.SOLVING:
            case SessionStatus.PENDING:
                return buildCancelSolveButton();
            case SessionStatus.SUBMITTING_CANCEL:
                return buildCancellingSolveButton();
            case SessionStatus.ARCHIVED:
                return buildArchivedButton();
            default:
                return buildSolveButton();
        }
    }

    const buildSolveButton = () => {
        return (
            <Button
                className="solve-button"
                type="primary"
                onClick={(e) => {
                    onSolve();
                    e.stopPropagation();
                }}
                disabled={!canSolve(cachedNLDraftDetail)}
            >
                <FunctionOutlined/> Solve
            </Button>
        );
    }

    const buildSubmittingSolveButton = () => {
        return (
            <Button
                className="solve-button"
                type="primary"
                disabled={true}
            >
                <LoadingOutlined/> Submitting
            </Button>
        );
    }

    const buildCancelSolveButton = () => {
        return (
            <Button
                className="solve-button"
                onClick={(e) => {
                    onCancelSolve();
                    e.stopPropagation();
                }}
                disabled={!canCancelSolve()}
            >
                <LoadingOutlined/> Cancel
            </Button>
        );
    }

    const buildCancellingSolveButton = () => {
        return (
            <Button
                className="solve-button"
                disabled={true}
            >
                <LoadingOutlined/> Cancelling
            </Button>
        );
    }

    const buildArchivedButton = () => {
        return (
            <Tooltip title="This session has been archived and can no longer be solved.">
                <Button
                    className="solve-button"
                    disabled={true}
                >
                    Archived
                </Button>
            </Tooltip>
        );
    }

    const onSolve = () => {
        if (!currentUser || !activeRepo) return;

        if (!session) {
            createAndSolve(cachedNLDraftDetail, activeRepo.fullName, activeRepo.defaultBranch).then(statusCode => {
                if (!statusCode) return;

                else if (statusCode >= 400) {
                    notification.error({
                        message: "Error solving",
                        description: <TraceableNotificationDescription
                            description="Solving could not be completed."
                            session_id={undefined}
                        />,
                        placement: "bottomRight"
                    })
                }
            });
        } else {
            solve(cachedNLDraftDetail).then(statusCode => {
                if (!statusCode) return;

                else if (statusCode >= 400) {
                    notification.error({
                        message: "Error solving",
                        description: <TraceableNotificationDescription
                            description="Solving could not be completed."
                            session_id={session?.session_id}
                        />,
                        placement: "bottomRight"
                    })
                }
            });
        }
    }

    const onCancelSolve = () => {
        if (!canCancelSolve()) return;

        cancelSolve().then(status_code => {
            if (!status_code) return;

            if (status_code >= 400) {
                notification.error({
                    message: "Error cancelling solve",
                    description: <TraceableNotificationDescription
                        description="Solve could not be cancelled."
                        session_id={session?.session_id}
                    />,
                    placement: "bottomRight"
                })
            }
        });
    }

    const onDetailEditorKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (processSolveHotkey(e)) return;
    }

    const processSolveHotkey = (e: React.KeyboardEvent<HTMLElement>): boolean => {
        if ((e.ctrlKey || e.metaKey) && e.key === "Enter" && canSolve(cachedNLDraftDetail)) {
            onSolve();
            (e.target as HTMLTextAreaElement).blur()
            e.preventDefault();
            return true;
        }

        return false;
    }

    return (
        <div className="nl-text-container" onClick={() => {
            detailEditorRef.current?.focus();
        }}>
            <DetailEditor
                ref={detailEditorRef}
                value={cachedNLDraftDetail}
                onChange={onDetailChange}
                onKeyDown={onDetailEditorKeyDown}
                placeholder="Write a task for the Solver, pose a problem, or ask a question"
            />
            <div className="solve-button-container">
                {buildButton()}
            </div>
        </div>
    )
};

export default NaturalLanguageInput;
