"use client";
import React, {useMemo} from "react";
import "./DiffCard.css"

import {Card, Collapse, CollapseProps} from "antd";
import {ChangeData, Diff, GutterOptions} from "react-diff-view";
import {DiffHeader, HeaderLines, FileInfo, getRelevantPath, highlightSyntax, renderHunk} from "./Utils";
import 'prism-themes/themes/prism-vsc-dark-plus.css';
import {CaretRightOutlined} from "@ant-design/icons";
import classNames from "classnames";

export const ImmutableDiffCard: React.FC<{
    fileInfo: FileInfo,
    highlightRange?: {start: number, end: number},
    expandCodeFn?: (start: number, end: number) => void,
    collapsable?: boolean
}> = ({fileInfo, highlightRange, expandCodeFn, collapsable}) => {
    const fileData = fileInfo.fileData;
    const filename = getRelevantPath(fileData);
    const hunks = fileData.hunks || [];

    const tokens = useMemo(
        () => highlightSyntax(hunks, fileInfo.source, filename), [hunks, filename, fileInfo.source]
    );

    const getWidgets = () => {
        return {}
    };

    const changeInRange = (change: ChangeData, range?: {start: number, end: number}) => {
        if (!range) {
            return false;
        }

        if (change.type === "insert" || change.type === "delete") {
            if (change.lineNumber && change.lineNumber >= range.start && change.lineNumber <= range.end) {
                return true;
            }
        } else if (change.type === "normal") {
            if (change.oldLineNumber && change.oldLineNumber >= range.start && change.oldLineNumber <= range.end) {
                return true;
            }
            if (change.newLineNumber && change.newLineNumber >= range.start && change.newLineNumber <= range.end) {
                return true;
            }
        }
        return false;
    }

    const renderGutter = (options: GutterOptions) => {
        if (changeInRange(options.change, highlightRange)) {
            const gutterClasses = classNames({
                "diff-gutter-highlight": true,
                "diff-gutter-highlight-new": options.side === "new",
            });

            return <a className={gutterClasses}>
                {options.renderDefault()}
            </a>
        } else {
            return <>{options.wrapInAnchor(options.renderDefault())}</>
        }
    };

    const buildDiff = () => {
        return (
            <Diff
                key={`${fileData.oldRevision}-${fileData.newRevision}`}
                gutterType="anchor"
                viewType="unified"
                diffType={fileData.type}
                widgets={getWidgets()}
                renderGutter={renderGutter}
                hunks={hunks}
                tokens={tokens}
            >
                {hunks => hunks.flatMap(
                    (hunk, idx, hunks) =>
                        renderHunk(hunk, idx, hunks, null, fileInfo.fileLines, expandCodeFn)
                )}
            </Diff>
        )
    }

    const buildDiffCard = (): CollapseProps['items'] => {
        return [{
            key: 0,
            label: <DiffHeader fileData={fileData}/>,
            children: buildDiff(),
            extra: <HeaderLines fileInfo={fileInfo}/>,
            className: "diff-card",
            style: {padding: "0px"},
        }]
    }

    if (collapsable) {
        return (
            <Collapse
                className="diff-card-container"
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                items={buildDiffCard()}
                defaultActiveKey={fileInfo.fileData.type !== "delete" ? [0] : undefined}
            />
        )
    } else {
        return (
            <Card title={<DiffHeader fileData={fileData}/>} extra={<HeaderLines fileInfo={fileInfo}/>} className="diff-card">
                {buildDiff()}
            </Card>
        )
    }
};
