"use client";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { ChangeSet, getChanges, useSession } from "../data/SolverSession";
import { ImmutableDiffCard } from "./ImmutableDiffCard";
import { ChangeSetSummmary, FileInfo, getRelevantPath } from "./Utils";

import { expandFromRawCode } from "react-diff-view";

interface SquashedViewProps { }

const getReviewChanges = async (session_id: string): Promise<ChangeSet> => {
    try {
        return await getChanges(session_id, {
            include_preimage: true,
            include_postimage: false
        });
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const SquashedView: React.FC<SquashedViewProps> = () => {
    const [reviewChanges, setReviewChanges] = useState<ChangeSet | null>(null);
    const session = useSession();

    useEffect(() => {
        if (session) {
            getReviewChanges(session.session_id).then(setReviewChanges);
        }
    }, [session]);

    // This effect runs only once when the component mounts
    useEffect(() => {
        setReviewChanges(null);
    }, []);

    const expandReviewChangesFile = (file_path: string, start: number, end: number) => {
        setReviewChanges(prevReviewChanges => {
            if (!prevReviewChanges) return null;

            const newFileInfos = prevReviewChanges.file_infos.map(fi => {
                if (getRelevantPath(fi.fileData) === file_path) {
                    const source = Array.isArray(fi.source) ? fi.source : (fi.source || '').split('\n')

                    fi.fileData.hunks = expandFromRawCode(fi.fileData.hunks, source, start, end);
                }

                return fi
            })

            return { ...prevReviewChanges, file_infos: newFileInfos }
        })
    }

    const buildDiffs = (reviewChanges: ChangeSet) => {
        return reviewChanges.file_infos.map((fileInfo: FileInfo) => {
            const fileData = fileInfo.fileData;
            const filePath = getRelevantPath(fileData);
            const diffCardKey = `${fileData.oldRevision}-${fileData.newRevision}`

            return <ImmutableDiffCard
                key={diffCardKey}
                fileInfo={fileInfo}
                expandCodeFn={(start: number, end: number) => expandReviewChangesFile(filePath, start, end)}
                collapsable={true}
            />
        })
    }

    if (reviewChanges === null) {
        return <div style={{ textAlign: "center", padding: "20px" }}><LoadingOutlined style={{ fontSize: "24px" }} /></div>;
    }

    if (reviewChanges.changes.length === 0) {
        return null;
    }

    return <div>
        <ChangeSetSummmary changeSet={reviewChanges} />
        <>{buildDiffs(reviewChanges)}</>
    </div>
};

export default SquashedView;
