import { AuthType } from "./User";

export default class AvatarVariantFactory {
    static createURLVariant(url: string, authType: AuthType, size: number): string {
        switch (authType) {
            case AuthType.GitHub: {
                const parsedUrl = new URL(url);
                const searchParams = parsedUrl.searchParams;

                searchParams.set("s", size.toString());

                parsedUrl.search = searchParams.toString();

                return parsedUrl.toString();
            }
            case AuthType.GitLab:
                // TODO: Leaving this for now since our support for GitLab is tenuous.
                // GitLab uses multiple hosts for avatars, so this will require a bit more work.
                return url;
            default:
                return url;
        }
    }
}
