"use client";
import { Card, List, Typography } from "antd";
import React, { useState } from "react";
import { AnyLinterError, BanditError, LinterContent } from "../data/TurnEventContent";
import "./LinterCard.css";

const { Link } = Typography;

const categoryNouns: { [key: string]: { singular: string; plural: string } } = {
    bandit: { singular: "potential vulnerability", plural: "potential vulnerabilities" },
    default: { singular: "problem", plural: "problems" }
};

const BanditErrorItem: React.FC<{ error: BanditError }> = ({ error }) => {
    return (
        <List.Item className="lint-error bandit-error">
            <div className="lint-error-content">
                <span className="lint-error-filename">{error.filename}:{error.line}</span>
                <span className="lint-error-message">{error.message}</span>
                {error.issue_cwe_id && (
                    <Link className="lint-error-link" href={error.issue_cwe_link} target="_blank" onClick={(e) => e.stopPropagation()}>
                        CWE-{error.issue_cwe_id}
                    </Link>
                )}
            </div>
        </List.Item>
    );
};

const LinterErrorItem: React.FC<{ error: AnyLinterError }> = ({ error }) => {
    if (error.linter_type === "bandit") {
        return <BanditErrorItem error={error} />;
    }
    return (
        <List.Item className="lint-error">
            <div className="lint-error-content">
                <span className="lint-error-filename">{error.filename}:{error.line}</span>
                <span className="lint-error-message">{error.message}</span>
            </div>
        </List.Item>
    );
};

interface LinterCardProps {
    content: LinterContent;
}

export const LinterCard: React.FC<LinterCardProps> = ({ content }) => {
    const [isSummary, setIsSummary] = useState(true);
    const uniqueFiles = new Set(content.errors.map(error => error.filename));

    const toggleView = () => {
        setIsSummary(!isSummary);
    };

    const renderSummary = () => {
        const categoryCounts: { [key: string]: number } = {};
        let defaultErrorCount = 0;

        content.errors.forEach((error: AnyLinterError) => {
            const category = error.linter_type as keyof typeof categoryNouns;
            if (category in categoryNouns && category !== 'default') {
                if (!categoryCounts[category]) {
                    categoryCounts[category] = 0;
                }
                categoryCounts[category]++;
            } else {
                defaultErrorCount++;
            }
        });

        const fileCount = uniqueFiles.size;
        let summaryText = '';

        Object.entries(categoryCounts).forEach(([category, count]) => {
            const { singular, plural } = categoryNouns[category as keyof typeof categoryNouns];
            summaryText += `${count} ${count === 1 ? singular : plural}, `;
        });

        if (defaultErrorCount > 0) {
            summaryText += `${defaultErrorCount} ${defaultErrorCount === 1 ? categoryNouns.default.singular : categoryNouns.default.plural}`;
        } else if (summaryText.length > 0) {
            summaryText = summaryText.slice(0, -2); // Remove the trailing ', '
        }

        if (fileCount > 1) {
            summaryText += ` across ${fileCount} files`;
        }

        return (
            <span>
                <span role="img" aria-label="warning">⚠️</span> {summaryText}
            </span>
        );
    };

    const renderDetails = () => {
        return (
            <List
                dataSource={content.errors}
                renderItem={error => <LinterErrorItem error={error} />}
            />
        );
    };
    if (content.errors.length > 0) {
        return (
            <Card
                className={`linter-card ${isSummary ? 'summary' : 'details'}`}
                onClick={toggleView}
            >
                <div className="linter-card-content">
                    {isSummary ? renderSummary() : renderDetails()}
                </div>
            </Card>
        );
    } else if (content.empty_message) {
        return (
            <Card className="linter-card empty">
                <div className="linter-card-content">
                    {content.empty_message}
                </div>
            </Card>
        );
    }
    return <></>
};
