import { ConfigProvider, theme } from "antd";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { SessionBrowsingProvider } from './data/SessionBrowsing';
import { SolverInterfaceContextProvider } from './data/SolverInterface';
import { SolverInvocationOptionsProvider } from './data/SolverInvocationOptions';
import { SessionProvider } from './data/SolverSession';
import './index.css';
import SolverApp from './SolverApp';

const { darkAlgorithm } = theme;

const SOLVER_BLUE = "#409ad4";
const SOLVER_BLUE_HOVER = "#5fa4cc";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ConfigProvider theme={{
            algorithm: darkAlgorithm,
            token: {
                colorPrimary: SOLVER_BLUE,
                colorPrimaryBorderHover: SOLVER_BLUE_HOVER,
            }
        }}>
            <SolverInterfaceContextProvider>
                <SolverInvocationOptionsProvider>
                    <SessionProvider>
                        <SessionBrowsingProvider>
                            <SolverApp/>
                        </SessionBrowsingProvider>
                    </SessionProvider>
                </SolverInvocationOptionsProvider>
            </SolverInterfaceContextProvider>
        </ConfigProvider>
    </React.StrictMode>
);

document.addEventListener("keydown", (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 's') e.preventDefault();
}, false);
