import React from "react";
import {Button, Space, Tooltip} from "antd";
import { SolverInterfaceStatus, useSolverInterfaceContext } from "../data/SolverInterface";
import { GithubOutlined, GitlabOutlined, LoadingOutlined } from "@ant-design/icons";
import { AuthType } from "../data/User";

const Startup: React.FC = () => {
    const { solverInterfaceStatus, login } = useSolverInterfaceContext();

    const startupElement = () => {
        switch (solverInterfaceStatus) {
            case SolverInterfaceStatus.ERROR:
                return <span className="startup-text">Failed to connect to Solver</span>
            case SolverInterfaceStatus.LOGGED_OUT:
                return (
                    <Space direction="vertical" size="large">
                        <Button className="startup-button" href={login(AuthType.GitHub)}>
                            <GithubOutlined/>Login with GitHub
                        </Button>
                        <Tooltip placement="bottom" title="Coming Soon">
                            <Button disabled={true} className="startup-button" href={login(AuthType.GitLab)}>
                                <GitlabOutlined/>Login with GitLab
                            </Button>
                        </Tooltip>
                    </Space>
                )
            case SolverInterfaceStatus.LOADING:
                return <LoadingOutlined style={{fontSize: "40px"}}/>
            case SolverInterfaceStatus.USER_NOT_ALLOWLISTED:
                return <div className="startup-text">
                    Thank you for your interest in using the Solver! We're working on rolling out
                    to more organizations and individuals. Please reach out to <a
                        className="startup-link"
                        href="mailto:earlyaccess@laredolabs.com?subject=Access to the Solver"
                    > earlyaccess@laredolabs.com</a> to request access.
                </div>
            default:
                return null
        }
    }

    return (
        <div className="startup">
            {startupElement()}
        </div>
    );
};

export default Startup;
