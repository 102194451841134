"use client";
import { InfoCircleOutlined, LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Collapse, CollapseProps, notification, Tooltip, Typography } from "antd";
import DOMPurify from 'dompurify';
import React, { forwardRef, MutableRefObject, useEffect, useState } from "react";
import { FileData, HunkData } from "react-diff-view";
import Markdown from "react-markdown";
import AvatarVariantFactory from "../data/AvatarVariantFactory";
import { TurnEventType } from "../data/SolverInterfaceEvent";
import {
    AgentThoughtTurnEvent,
    ChangeSet,
    DocumentationEvent,
    EventChangeSetState,
    ExecutionEvent,
    FileImage,
    LinterErrorsEvent,
    ProfileEvent,
    RelevantFilesEvent,
    sessionIsLoading,
    SessionStatus,
    SolutionReviewEvent,
    Turn,
    TurnEvent,
    useAmendTurn,
    useCanModifySession,
    useEventChangeSets,
    useExpandEventFile,
    useRevertHunk,
    useSession,
    useSessionStatus,
    useSolve,
    useTurns,
    useUndoTurn,
} from "../data/SolverSession";
import { RelevantFilesCard } from "./ChangeLocalizationsCard";
import { ImmutableDiffCard } from "./ImmutableDiffCard";
import { MessageProps } from "./Message";
import MessageGroup from "./MessageGroup";
import { MessageType } from "./MessageType";
import { MutableDiffCard } from "./MutableDiffCard";
import TraceableNotificationDescription from "./TraceableNotificationDescription"; // Adjust the import path as necessary
import {
    ChangeSetSummmary,
    FileInfo,
    getRelevantPath,
    toFileInfos,
} from "./Utils";

import classNames from "classnames";
import remarkGfm from 'remark-gfm';
import { Repo } from "../data/Repos";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { BisectContent, BisectRevisionState, BlameContent, CodeCoverageContent } from "../data/TurnEventContent";
import solverAvatar from "../images/ll_logo_icon_square.png";
import "./Bisect.css";
import "./CodeCoverage.css";
import "./Conversation.css";
import { LinterCard } from "./LinterCard";

interface ConversationProps {
    onShowChangesView: () => void;
}

export type ContainerRefT = HTMLElement;

const renderAsMarkdown = (text: string, linkInNewTab: boolean = true) => {
    return <Markdown
        remarkPlugins={[remarkGfm]}
        className="markdown-preview"
        components={{
            p: ({ className, children, ...props }) => {
                return <p className={`${className ? className : ""} markdown-preview-paragraph`} {...props}>{children}</p>
            },
            code: ({ className, children, ...props }) => {
                return <code className={`${className ? className : ""} markdown-preview-code`} {...props}>{children}</code>
            },
            pre: ({ className, children, ...props }) => {
                return <pre className={`${className ? className : ""} markdown-preview-pre`} {...props}>{children}</pre>
            },
            img: ({ className, src, alt, ...props }) => {
                return <img className={`${className ? className : ""} markdown-preview-img`} src={src} alt={alt} {...props} />
            },
            table: ({ className, children, ...props }) => {
                return <table className={`${className ? className : ""} markdown-preview-table`} {...props}>{children}</table>
            },
            thead: ({ className, children, ...props }) => {
                return <thead className={`${className ? className : ""} markdown-preview-thead`} {...props}>{children}</thead>
            },
            tbody: ({ className, children, ...props }) => {
                return <tbody className={`${className ? className : ""} markdown-preview-tbody`} {...props}>{children}</tbody>
            },
            tr: ({ className, children, ...props }) => {
                return <tr className={`${className ? className : ""} markdown-preview-tr`} {...props}>{children}</tr>
            },
            th: ({ className, children, ...props }) => {
                return <th className={`${className ? className : ""} markdown-preview-th`} {...props}>{children}</th>
            },
            td: ({ className, children, ...props }) => {
                return <td className={`${className ? className : ""} markdown-preview-td`} {...props}>{children}</td>
            },
            a: ({ children, ...props }) => {
                return (
                    <a href={props.href} target={linkInNewTab ? "_blank" : "_self"} rel="noreferrer noopener">
                        {children}
                    </a>
                );
            }
        }}
    >
        {text}
    </Markdown>
}

const Conversation = forwardRef<ContainerRefT, ConversationProps>(({ onShowChangesView }, containerRef) => {
    const turns = useTurns();
    const eventChangeSets = useEventChangeSets();
    const session = useSession();
    const sessionStatus = useSessionStatus();
    const canModifySession = useCanModifySession();
    const revertHunk = useRevertHunk();
    const amendTurn = useAmendTurn();
    const undoTurn = useUndoTurn();
    const expandEventFile = useExpandEventFile();
    const solve = useSolve();
    const { activeRepo } = useSolverInterfaceContext();

    const [userDidScroll, setUserDidScroll] = useState(false);

    function handleSuggestionClick(suggestion: string) {
        if (!canModifySession()) return;

        solve(suggestion).then(statusCode => {
            if (!statusCode) return;

            else if (statusCode >= 400) {
                notification.error({
                    message: "Error solving",
                    description: <TraceableNotificationDescription
                        description="Solving could not be completed."
                        session_id={session?.session_id}
                    />,
                    placement: "bottomRight"
                })
            }
        });
    }

    useEffect(() => {
        const containerElement = getContainerElement();
        if (!containerElement) return;

        const onScroll = () => {
            setTimeout(() => {
                setUserDidScroll(!elementIsFullyScrolled(containerElement));
            }, 100)
        }

        containerElement.addEventListener("scroll", onScroll);

        return () => containerElement.removeEventListener("scroll", onScroll);
    }, [])

    useEffect(() => {
        if (sessionStatus === SessionStatus.SOLVING) {
            scrollToBottom();
        }
    }, [sessionStatus, turns, eventChangeSets, userDidScroll]);

    const scrollToBottom = () => {
        const containerElement = getContainerElement();
        if (!containerElement) return false;

        // If the user scrolled up, don't scroll to the bottom.
        if (userDidScroll) {
            return;
        }

        containerElement.scrollTop = containerElement.scrollHeight;
    }

    const elementIsFullyScrolled = (element: HTMLElement) => {
        return Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 5;
    }

    const getContainerElement = (): HTMLElement | undefined => {
        const containerRefAsRef = (containerRef as MutableRefObject<ContainerRefT | null>);

        if (!containerRefAsRef.current) return undefined;

        return containerRefAsRef.current;
    }

    const buildChangesMessageContent = (event_id: string, changeSet: ChangeSet, turn: Turn) => {
        return <div className="turn-changes">
            {buildExtra(changeSet)}
            {buildDiffs(event_id, changeSet, turn)}
        </div>
    }

    const buildDiffs = (event_id: string, changeSet: ChangeSet, turn: Turn) => {
        const mutable = turn.idx === turns.length - 1;
        const postimages = new Map<string, FileImage>(
            changeSet.postimages?.map((image: FileImage) => [image.file_path, image])
        )

        const diffCardKey = (fileData: FileData) => `${fileData.oldRevision}-${fileData.newRevision}`

        // We collect diffs with their paths to sort them by path.
        const codeDiffsAndFilenames: [JSX.Element, string][] = changeSet.file_infos.map((fileInfo: FileInfo) => {
            const filePath = getRelevantPath(fileInfo.fileData);

            if (mutable) {
                const postImage = postimages.get(getRelevantPath(fileInfo.fileData));
                const highlights = fileInfo.fileData.hunks.map((hunk: HunkData, idx: number) => {
                    return {
                        startChange: hunk.changes[0],
                        endChange: hunk.changes[hunk.changes.length - 1],
                        revertHunkFn: () => revertHunk(turn.id, fileInfo.change_ids[idx]),
                        revertHunkDisabled: !canModifySession(),
                    }
                })

                return [<MutableDiffCard
                    key={diffCardKey(fileInfo.fileData)}
                    fileInfo={fileInfo}
                    postImage={postImage}
                    hunkHighlights={highlights}
                    updateFileFn={(fileImage: FileImage) => amendTurn(turn.id, turn.nl_text, [fileImage])}
                    updateFileDisabled={!canModifySession()}
                    expandCodeFn={(start: number, end: number) => expandEventFile(event_id, filePath, start, end)}
                />, getRelevantPath(fileInfo.fileData)]
            } else {
                return [<ImmutableDiffCard
                    key={diffCardKey(fileInfo.fileData)}
                    fileInfo={fileInfo}
                    expandCodeFn={(start: number, end: number) => expandEventFile(event_id, filePath, start, end)}
                />, getRelevantPath(fileInfo.fileData)]
            }
        })

        codeDiffsAndFilenames.sort((a, b) => {
            return a[1].localeCompare(b[1])
        })

        return codeDiffsAndFilenames.map(diff => diff[0])
    }

    const buildExtra = (changeSet: ChangeSet) => {
        if (sessionStatus === SessionStatus.SOLVING) {
            return undefined;
        }

        return <ChangeSetSummmary changeSet={changeSet} />
    }

    const turnThread = (turn: Turn) => {
        return <React.Fragment key={turn.id}>
            {promptMessage(turn)}
            {eventMessages(turn)}
        </React.Fragment>
    }

    const eventMessages = (turn: Turn) => {
        const isLastTurn: boolean = turn.idx === turns.length - 1;

        const events = turn.events
            .filter(turnEvent => !shouldExcludeMessage(turnEvent));

        const messageProps: MessageProps[] = events.map((turnEvent: TurnEvent) => {
            return {
                key: turnEvent.id,
                renderContent: () => eventMessageContent(turnEvent, turn),
                messageType: MessageType.AGENT,
                eventType: turnEvent.event_type,
                ...eventMessageCollapseProps(turnEvent),
            }
        })

        const messageGroupProps = {
            messages: messageProps,
            messageType: MessageType.AGENT,
            collapsible: !isLastTurn || (isLastTurn && !sessionIsLoading(sessionStatus)),
            collapsed: !isLastTurn,
            avatar: solverAvatar
        }

        return <MessageGroup {...messageGroupProps} />
    }

    const shouldExcludeMessage = (turnEvent: TurnEvent): boolean => {
        if (turnEvent.event_type === TurnEventType.SOLVER_LOG) return true;
        if (!turnEvent.content) return true;

        if (turnEvent.event_type === TurnEventType.AGENT_THOUGHT) {
            const agentThought = turnEvent as AgentThoughtTurnEvent;
            return !agentThought.content.message || agentThought.content.message === "";
        }

        return false;
    }

    const promptMessage = (turn: Turn) => {
        if (!session) return undefined;

        const isLastTurn: boolean = turn.idx === turns.length - 1;
        const avatarUrl = AvatarVariantFactory.createURLVariant(session.user_avatar_url, session.auth_type, 48);

        return <MessageGroup
            messages={[
                {
                    renderContent: () => {
                        return (
                            <div className="user-message-content">
                                {renderAsMarkdown(turn.nl_text)}
                                <div className="user-message-content-extra">
                                    {isLastTurn && buildUndoButton(turn)}
                                    {turn.error && <Tooltip title={turn.error || ""} placement="left" arrow={false}>
                                        <WarningOutlined />
                                    </Tooltip>}
                                </div>
                            </div>
                        )
                    },
                    messageType: MessageType.USER,
                    collapsible: false,
                    key: turn.id
                }
            ]}
            messageType={MessageType.USER}
            collapsible={false}
            collapsed={false}
            avatar={avatarUrl}
        />
    }

    const buildUndoButton = (turn: Turn) => {
        return (
            <Tooltip title="Undo this thread" placement="left" arrow={false}>
                <Button
                    className="undo-button"
                    size="small"
                    disabled={!canModifySession()}
                    onClick={e => {
                        e.stopPropagation();
                        undoTurn(turn.id)
                    }}
                >
                    <span className="small-button-text">Undo</span>
                </Button>
            </Tooltip>
        )
    }

    const eventMessageCollapseProps = (turnEvent: TurnEvent) => {
        if (turnEvent.event_type === TurnEventType.RELEVANT_FILES) {
            return {
                collapsible: true,
                defaultExpanded: false,
            }
        } else if (turnEvent.event_type === TurnEventType.LINT_ERRORS) {
            return {
                collapsible: false
            }
        }
        return {
            collapsible: true,
        }
    }

    const eventMessageContent = (turnEvent: TurnEvent, turn: Turn) => {
        const handleSvgRef = (node: HTMLDivElement | null) => {
            if (node) {
                const svgElement = node.querySelector("svg");
                if (svgElement) {
                    svgElement.style.width = "100%";
                    svgElement.style.height = "auto";
                    svgElement.style.maxHeight = "none";  // Ensure no max height constraint
                }
            }
        };

        switch (turnEvent.event_type) {
            case TurnEventType.AGENT_THOUGHT: {
                const agentThought = turnEvent as AgentThoughtTurnEvent;

                return renderAsMarkdown(agentThought.content.message)
            }
            case TurnEventType.TURN_CHANGES: {
                const turnChangesChangeSet = eventChangeSets.get(turnEvent.id);

                if (!turnChangesChangeSet || turnChangesChangeSet.state === EventChangeSetState.LOADING) {
                    return <div className="message-loading"><LoadingOutlined className="message-loading-icon" /></div>
                } else if (turnChangesChangeSet.state === EventChangeSetState.ERROR) {
                    return <Typography.Text><WarningOutlined /> Failed to load message</Typography.Text>
                }

                return buildChangesMessageContent(turnEvent.id, turnChangesChangeSet.changeSet, turn)
            }
            case TurnEventType.DOCUMENTATION: {
                const documentation = turnEvent as DocumentationEvent;
                return renderAsMarkdown(documentation.content.summary)
            }
            case TurnEventType.CODE_COVERAGE: {
                const codeCoverage = turnEvent.content as CodeCoverageContent;
                return buildCodeCoverageMessageContent(codeCoverage);
            }
            case TurnEventType.EXECUTION: {
                const execution = turnEvent as ExecutionEvent;
                return buildExecutionMessageContent(
                    execution.content.command,
                    execution.content.stdout,
                    execution.content.stderr,
                    execution.content.completed,
                    execution.content.exit_code
                );
            }
            case TurnEventType.EDIT:
            case TurnEventType.REVERT: {
                const editChangeSet = eventChangeSets.get(turnEvent.id);

                if (!editChangeSet || editChangeSet.state === EventChangeSetState.LOADING) {
                    return <div className="message-loading"><LoadingOutlined className="message-loading-icon" /></div>
                } else if (editChangeSet.state === EventChangeSetState.ERROR) {
                    return <Typography.Text type="danger">Failed to load message</Typography.Text>
                }

                return editChangeSet.changeSet.file_infos
                    .map((fileInfo: FileInfo) => {
                        const fileData = fileInfo.fileData;
                        const diffCardKey = `${fileData.oldRevision}-${fileData.newRevision}`
                        return <ImmutableDiffCard key={diffCardKey} fileInfo={fileInfo} />
                    })
            }
            case TurnEventType.PROFILE: {
                const profileEvent = turnEvent as ProfileEvent;
                if (profileEvent.content.svg) {
                    const sanitizedSvg = DOMPurify.sanitize(profileEvent.content.svg);
                    return (
                        <div style={{ width: "100%", height: "auto", maxWidth: "100%", overflow: "hidden", margin: "10px 0", padding: "10px 0 20px 0" }}>
                            <div
                                ref={handleSvgRef}
                                dangerouslySetInnerHTML={{ __html: sanitizedSvg }}
                                style={{ width: "100%", height: "auto", maxWidth: "100%", display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden" }}
                            />
                        </div>
                    );
                }
                if (profileEvent.content.error) {
                    return buildExecutionMessageContent(
                        null,  // No command for profile errors
                        undefined,  // No stdout for profile errors
                        profileEvent.content.error,
                        true,
                        1,
                        false  // Don't show labels for profiler errors
                    );
                }
                return <Typography.Text type="warning">No profile data available.</Typography.Text>
            }

            case TurnEventType.SOLUTION_REVIEW:
                const summary = turnEvent as SolutionReviewEvent;

                if (summary.content.error) {
                    return null;
                }

                const codeReviewIssues = Array.isArray(summary.content.review_issues) ? summary.content.review_issues.slice(0, 3) : [];
                const guidelinesSuggestions = Array.isArray(summary.content.guidelines_suggestions) ? summary.content.guidelines_suggestions.slice(0, 3) : [];
                const taskSuggestions = Array.isArray(summary.content.task_suggestions) ? summary.content.task_suggestions.slice(0, 3) : [];

                return (
                    <div>
                        {summary.content.init ? (
                            renderAsMarkdown(summary.content.init_message)
                        ) : (
                            <>
                                <Tooltip title={summary.content.task_summary}>
                                    <InfoCircleOutlined style={{ position: 'absolute', top: '10px', right: '10px' }} />
                                </Tooltip>

                                {renderAsMarkdown("### Summary:")}
                                {renderAsMarkdown(summary.content.impl_summary)}

                                <div style={{ marginTop: '10px' }}>
                                    {taskSuggestions.map((suggestion, index) => (
                                        <Button
                                            key={index}
                                            className="solver-finished-cta"
                                            style={{ display: 'block', marginBottom: '10px' }}
                                            onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                            {renderAsMarkdown(suggestion)}
                                        </Button>
                                    ))}
                                </div>

                                <br />
                                {renderAsMarkdown("### Code Review:")}
                                {renderAsMarkdown(summary.content.review_summary)}

                                <div style={{ marginTop: '10px' }}>
                                    {codeReviewIssues.map((suggestion, index) => (
                                        <Button
                                            key={index}
                                            className="solver-finished-cta"
                                            style={{ display: 'block', marginBottom: '10px' }}
                                            onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                            {renderAsMarkdown(suggestion)}
                                        </Button>
                                    ))}
                                </div>

                                {summary.content.guidelines_summary && (
                                    <>
                                        <br />
                                        {renderAsMarkdown("### Guidelines Review:")}
                                        {renderAsMarkdown(summary.content.guidelines_summary)}

                                        <div style={{ marginTop: '10px' }}>
                                            {guidelinesSuggestions.map((suggestion, index) => (
                                                <Button
                                                    key={index}
                                                    className="solver-finished-cta"
                                                    style={{ display: 'block', marginBottom: '10px' }}
                                                    onClick={() => handleSuggestionClick(suggestion)}
                                                >
                                                    {renderAsMarkdown(suggestion)}
                                                </Button>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                );

            case TurnEventType.RELEVANT_FILES: {
                const relevantFilesEvent = turnEvent as RelevantFilesEvent;
                if (relevantFilesEvent.content.pending_message) {
                    return <Typography.Text type="secondary"> {relevantFilesEvent.content.pending_message} </Typography.Text>;
                } else {
                    return <RelevantFilesCard files={relevantFilesEvent.content.files} />;
                }
            }
            case TurnEventType.LINT_ERRORS: {
                const linterErrorsEvent = turnEvent as LinterErrorsEvent;
                return <LinterCard content={linterErrorsEvent.content} />;
            }
            case TurnEventType.SUBMIT:
                return <div className="solver-finished-message">
                    <strong>Solver finished</strong>
                    <Button className="solver-finished-cta" onClick={onShowChangesView}>
                        View repository changes
                    </Button>
                </div>
            case TurnEventType.RESOURCES_EXHAUSTED:
                return <div className="solver-finished-message">
                    <strong>Exhausted response limit</strong>
                    <Button className="solver-finished-cta" onClick={onShowChangesView}>
                        View repository changes
                    </Button>
                </div>
            case TurnEventType.BLAME: {
                if (!activeRepo) {
                    return null;
                }

                const blameContent = turnEvent.content as BlameContent;
                if (blameContent.error_type) {
                    return (
                        <div>
                            {renderAsMarkdown(blameContent.message)}
                            <Typography.Text type="danger">
                                {blameContent.error}
                            </Typography.Text>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            {renderAsMarkdown(blameContent.message)}
                            <div onClick={e => e.stopPropagation()}>
                                <Collapse
                                    className="blame-event-collapse"
                                    items={buildBlameCommitContent(activeRepo, blameContent)}
                                    defaultActiveKey={[]}
                                />
                            </div>
                        </div>
                    )
                }
            }
            case TurnEventType.BISECT: {
                if (!activeRepo) {
                    return null;
                }

                const bisectContent = turnEvent.content as BisectContent;
                return buildBisectContent(activeRepo, bisectContent);
            }
            default:
                return <Markdown
                    className="markdown-preview"
                    components={{
                        pre: ({ className, children, ...props }) => {
                            return <pre className={`${className ? className : ""} markdown-preview-pre`} {...props}>{children}</pre>
                        },
                    }}
                >
                    {`\`\`\`\n${JSON.stringify(turnEvent.content, null, 2)}\n\`\`\``}
                </Markdown>
        }
    }

    if (!activeRepo) {
        return null;
    }

    return (
        <>
            {turns.map((turn: Turn) => turnThread(turn))}
        </>
    )
});

const buildExecutionMessageContent = (
    command: string | null,
    out: string | undefined,
    err: string | undefined = undefined,
    completed: boolean = true,
    exit_code: number = 0,
    showLabels: boolean = true
) => {
    return (
        <div>
            {command && (
                <pre className="terminal">
                    $ {command}
                    <br />
                    {out}
                </pre>
            )}
            {err && (
                <div>
                    {showLabels && (
                        <Typography.Text type='danger'>
                            Stderr:
                        </Typography.Text>
                    )}
                    <pre className="terminal danger">
                        {err}
                    </pre>
                </div>
            )}
            {completed && showLabels && (
                <Typography.Text type={exit_code === 0 ? 'success' : 'danger'}>
                    Exit Code: {exit_code}
                </Typography.Text>
            )}
        </div>
    );
}

const buildCodeCoverageMessageContent = (codeCoverage: CodeCoverageContent) => {
    const computeBucket = (coverage: number) => {
        if (coverage >= 85) {
            return 'great';
        } else if (coverage >= 70) {
            return 'good';
        } else if (coverage >= 55) {
            return 'ok';
        } else if (coverage >= 35) {
            return 'bad';
        } else if (coverage >= 15) {
            return 'terrible';
        } else {
            return 'zero';
        }
    }

    if (codeCoverage.error_code) {
        return buildExecutionMessageContent(
            codeCoverage.command,
            codeCoverage.message,
            codeCoverage.error_output,
            true,
            1
        )
    } else {
        return (
            <div>
                {buildExecutionMessageContent(
                    codeCoverage.command,
                    `${codeCoverage.message}\nTotal coverage: ${codeCoverage.coverage.toPrecision(3)}%`
                )}
                <table className="coverage-table">
                    <tbody>
                        {codeCoverage.definitions.map((definition) => {
                            return (
                                <tr key={definition.definition} className="coverage-row">
                                    <td className="coverage-arrow">&#x21B3;</td>
                                    <td className="coverage-definition">{definition.definition}</td>
                                    <td className="coverage-coverage">{definition.coverage.toPrecision(3)}%</td>
                                    <td className="coverage-bar">
                                        <div
                                            className={`coverage-bar-inner ${computeBucket(definition.coverage)}`}
                                            style={{ width: `${definition.coverage}%` }}
                                            data-coverage={definition.coverage.toPrecision(3)}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        );

    }
}

const buildCommitLabel = (author: string, date: string, message: string) => {
    return (
        <div className="blame-commit-label">
            <div className="blame-commit-message">{message}</div>
            <div className="blame-commit-author-date">{`By: ${author} on ${date}`}</div>
        </div>
    )
}

const buildGithubLinkButton = (activeRepo: Repo, commit: string) => {
    return <Button
        size="small"
        type="link"
        href={`https://github.com/${activeRepo.fullName}/commit/${commit}`}
        target="_blank" className="commit-button"
        onClick={e => e.stopPropagation()}
    >
        {commit.slice(0, 7)}
    </Button>
}

const buildBlameCommitContent = (activeRepo: Repo, content: BlameContent): CollapseProps['items'] => {
    const lineRange = content.line_start && content.line_end ?
        { start: content.line_start, end: content.line_end } : undefined;
    return content.commits.map((commit, idx) => {
        return {
            key: idx,
            label: buildCommitLabel(commit.author, commit.date, commit.message),
            extra: buildGithubLinkButton(activeRepo, commit.commit),
            children: toFileInfos([{ patch: commit.patch, change_ids: [] }], []).map((fileInfo: FileInfo) => {
                const diffCardKey = (fileData: FileData) => `${fileData.oldRevision}-${fileData.newRevision}`
                return <ImmutableDiffCard
                    key={diffCardKey(fileInfo.fileData)}
                    highlightRange={fileInfo.fileData.newPath === content.path || fileInfo.fileData.oldPath === content.path ? lineRange : undefined}
                    fileInfo={fileInfo}
                    expandCodeFn={() => { }}
                />
            }),
        }
    })
}

const buildBisectRevisionContent = (
    bisect_state: "started" | "running" | "finished",
    current_revision?: string,
    test_revisions?: { [revision: string]: { state: BisectRevisionState, message: string } }
) => {
    if (!test_revisions) {
        return null;
    }

    return (
        <table className="bisect-revisions-table">
            <tbody>
                {Object.entries(test_revisions).map(([commit, { state: state, message }]) => {
                    if (bisect_state === "finished" && state === "pruned") {
                        return null;
                    }

                    const revisionClass = classNames({
                        "bisect-revision": true,
                        "bisect-revision-untested": state === "untested",
                        "bisect-revision-good": state === "good",
                        "bisect-revision-bad": state === "bad",
                        "bisect-revision-pruned": state === "pruned",
                        "bisect-revision-latest": commit === current_revision,
                    })
                    const revisionCommitClass = classNames({
                        "bisect-revision-commit": true,
                        "bisect-revision-commit-untested": state === "untested",
                        "bisect-revision-commit-good": state === "good",
                        "bisect-revision-commit-bad": state === "bad",
                        "bisect-revision-commit-pruned": state === "pruned",
                    })
                    const revisionStateClass = classNames({
                        "bisect-revision-state": true,
                        "bisect-revision-state-untested": state === "untested",
                        "bisect-revision-state-good": state === "good",
                        "bisect-revision-state-bad": state === "bad",
                        "bisect-revision-state-pruned": state === "pruned",
                    })
                    return (
                        <tr className={revisionClass} key={commit}>
                            <td className="bisect-revision-latest-marker">{commit === current_revision ? "*" : ""}</td>
                            <td className={revisionStateClass}>{state}</td>
                            <td className={revisionCommitClass}>{commit}</td>
                            <td className="bisect-revision-message">{message}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    )
}

const buildBisectContent = (activeRepo: Repo, content: BisectContent) => {

    if (content.error_message) {
        return (
            <div>
                {renderAsMarkdown(`There was an error running \`bisect\`: ${content.error_message}`)}
            </div>
        );
    }

    return (
        <div className="bisect-content">
            {renderAsMarkdown(`I'm running \`bisect\` to search for the commit that introduced the issue in \`${content.target}\`...`)}
            {content.test_revisions && buildBisectRevisionContent(content.state, content.last_tested_rev, content.test_revisions)}
            {content.state === "started" && renderAsMarkdown("Starting to run \`bisect\` process...")}
            {content.state === "running" && renderAsMarkdown(`Testing \`${content.target}\` on bisected revisions...`)}
            {content.state === "finished" && renderAsMarkdown(`Finished running \`bisect\`. The breaking commit is \`${content.breaking_commit.commit}\`!`)}
            {content.breaking_commit && (
                <div className="bisect-breaking-commit">
                    {buildCommitLabel(content.breaking_commit.author, content.breaking_commit.date, content.breaking_commit.message)}
                    {toFileInfos([{ patch: content.breaking_commit.patch, change_ids: [] }], []).map((fileInfo: FileInfo) => {
                        const diffCardKey = (fileData: FileData) => `${fileData.oldRevision}-${fileData.newRevision}`
                        return <ImmutableDiffCard
                            key={diffCardKey(fileInfo.fileData)}
                            fileInfo={fileInfo}
                            expandCodeFn={undefined}
                        />
                    })}
                </div>
            )}
        </div>
    );
}

Conversation.displayName = "Conversation";

export default Conversation;
