import axios from "axios";

// TODO: every API endpoint interface should probably live here
// because they're shared between SolverSession and SessionBrowsing.

const DEV_URL = "http://localhost:11933";
const ENG_URL = "https://interface.eng.laredolabs.com";
const PROD_URL = "https://interface.solver.laredolabs.com";

const getRemoteUrl = (host: string) => {
    const domainParts = host.split(".");
    if (domainParts.length < 2) return DEV_URL;

    const domainEnv = domainParts[1];
    switch (domainEnv) {
        case "eng":
            return ENG_URL;
        case "solver":
            return PROD_URL;
        default:
            return DEV_URL;
    }
}

export const SOLVER_INTERFACE_URL_BASE = getRemoteUrl(window.location.hostname);
// An instance of axios to use with all /api endpoints.
export const solverInterfaceApiAxios = axios.create({
    baseURL: `${SOLVER_INTERFACE_URL_BASE}/api`,
    withCredentials: true,
});
