import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";

export type Repo = {
    fullName: string;
    org: string;
    name: string;
    icon: string;
    defaultBranch: string | undefined;
    branches: string[],
}

const getRepos = async (): Promise<Repo[]> => {
    return await solverInterfaceApiAxios
        .get<Repo[]>(`/repos`)
        .then(response => response.data)
        .catch(error => {
            console.log(error);
            return [];
        });
}

export {
    getRepos,
}
