"use client";
import React from "react";

import { Checkbox } from "antd";

import "./FilterList.css";

export type FilterListItem = {
    key: string;
    label: string;
    iconUrl?: string;
}

const FilterList: React.FC<{
    items: FilterListItem[];
    selectedKeys?: string[];
    onSelect: (key: string) => void;
    onDeselect?: (key: string) => void;
    selectable?: boolean;
}> = ({
    items,
    selectedKeys = [],
    onSelect,
    onDeselect,
    selectable = true
}) => {
    const buildItem = (item: FilterListItem) => {
        const isSelected = selectedKeys.includes(item.key);

        return <div
            onClick={() => {
                if (isSelected) {
                    onDeselect && onDeselect(item.key);
                } else {
                    onSelect(item.key);
                }
            }}
            className="filter-list-item"
        >
            {selectable && <Checkbox checked={isSelected}/>}
            {item.iconUrl && <img className="filter-list-item-icon" src={item.iconUrl} alt={item.label}/>}
            <span className="filter-list-item-label">{item.label}</span>
        </div>
    }

    return (
        <div className="filter-list">
            {items.map(buildItem)}
        </div>
    );
}

export default FilterList;
