import React, { createContext, useEffect, useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { notification } from "antd";
import qs from "qs";
import { useCookies } from "react-cookie";

import { getRepos, Repo } from "./Repos"
import { SolverInterfaceEventType } from "./SolverInterfaceEvent";
import { SOLVER_INTERFACE_URL_BASE, solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import { AuthType, User } from "./User"

import {
    useStreamConnection,
    SolverInterfaceEventObserver,
    SolverInterfaceEventObserverHandle,
    StreamConnectionStatus
} from "../hooks/useStreamConnection";

export enum SolverInterfaceStatus {
    LOGGED_IN,
    LOGGED_OUT,
    USER_NOT_ALLOWLISTED,
    LOADING,
    ERROR
}

type GetUserResult = {
    user: User | undefined;
    solverInterfaceStatus: SolverInterfaceStatus;
}

export type SolverInterfaceContextType = {
    solverInterfaceStatus: SolverInterfaceStatus;
    loggedIn: boolean;
    appIsReady: boolean;
    currentUser: User | undefined;
    login: (authType: AuthType) => string;
    logout: () => void;
    repos: Repo[];
    loadingRepos: boolean;
    activeRepo: Repo | undefined;
    setActiveRepo: (repo: Repo) => void;
    connectToSolverInterface: () => void;
    addSolverInterfaceEventObserver: (
        solverInterfaceEventType: SolverInterfaceEventType,
        observer: SolverInterfaceEventObserver
    ) => SolverInterfaceEventObserverHandle;
    removeSolverInterfaceEventObserver: (handle: SolverInterfaceEventObserverHandle) => void;
    onStreamConnectionErrorResponse: (status: number) => void;
    onStreamReconnectionFailed: () => void;
}

const SolverInterfaceContext = createContext<SolverInterfaceContextType | undefined>(undefined);

const SolverInterfaceContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const [solverInterfaceStatus, setSolverInterfaceStatus] = useState<SolverInterfaceStatus>(SolverInterfaceStatus.LOADING);
    const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);

    const [activeRepo, setActiveRepo] = useState<Repo | undefined>(undefined);

    const [loadingRepos, setLoadingRepos] = useState<boolean>(true);
    const [repos, setRepos] = useState<Repo[]>([]);
    const [cookie, setCookie, removeCookie] = useCookies(["lastRepo"])

    const [api, contextHolder] = notification.useNotification();

    const loggedIn = solverInterfaceStatus === SolverInterfaceStatus.LOGGED_IN;

    const onStreamConnectionErrorResponse = (status: number) => {
        switch (status) {
            case 401:
                onUnauthenticated();
                break;
            case 403:
                onUnauthorized();
                break;
            default:
                console.warn("Unexpected response code from /api/connect:", status)

                setSolverInterfaceStatus(SolverInterfaceStatus.ERROR);
        }
    }

    const onStreamReconnectionFailed = () => {
        api.error({
            message: "Connection lost",
            description: "Please try refreshing the page",
            placement: "bottomRight",
            duration: null,
            key: "reconnection-failed",
        });
    }

    const onUnauthenticated = () => {
        api.error({
            message: "You have been logged out",
            placement: "bottomRight",
            duration: null,
            key: "unauthorized",
        });

        logout();
    }

    const onUnauthorized = () => {
        api.error({
            message: "Unauthorized",
            description: "You do not have access.",
            placement: "bottomRight",
            duration: 3,
            key: "unauthorized",
        });

        setSolverInterfaceStatus(SolverInterfaceStatus.ERROR);
    }

    const {
        connect,
        streamConnectionStatus,
        addSolverInterfaceEventObserver,
        removeSolverInterfaceEventObserver,
    } = useStreamConnection(onStreamConnectionErrorResponse, onStreamReconnectionFailed)

    const appIsReady = solverInterfaceStatus === SolverInterfaceStatus.LOGGED_IN &&
        !!activeRepo &&
        streamConnectionStatus === StreamConnectionStatus.CONNECTED;

    useEffect(() => {
        const interceptor = solverInterfaceApiAxios.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error: AxiosError) => {
                if (error.response && error.response.status === 401) {
                    onUnauthenticated();
                } else if (error.response && error.response.status === 403) {
                    onUnauthorized();
                }

                return Promise.reject(error);
            }
        )

        return () => {
            solverInterfaceApiAxios.interceptors.response.eject(interceptor);
        }
    }, [])

    useEffect(() => {
        if (activeRepo) {
            connect(`${SOLVER_INTERFACE_URL_BASE}/api/connect/repos/${activeRepo.org}/${activeRepo.name}`)
        }
    }, [activeRepo])

    const connectToSolverInterface = async () => {
        const getUserResult: GetUserResult = await getUser();

        setCurrentUser(getUserResult.user);
        setSolverInterfaceStatus(getUserResult.solverInterfaceStatus);

        if (getUserResult.solverInterfaceStatus === SolverInterfaceStatus.LOGGED_IN) {
            updateRepos();
        }
    }

    const setActiveRepoFn = (repo: Repo | undefined) => {
        setActiveRepo(repo)
        if (repo) {
            setCookie("lastRepo", repo.fullName, {path: "/", maxAge: 60 * 60 * 24 * 30})
        } else {
            removeCookie("lastRepo", {path: "/"})
        }
    }

    const updateRepos = async () => {
        setLoadingRepos(true)

        const loadedRepos = await getRepos()

        setRepos(loadedRepos)
        setLoadingRepos(false)

        if (loadedRepos && loadedRepos.length > 0) {
            const repo = loadedRepos.find((repo) => repo.fullName === cookie?.lastRepo);
            // This repo could have added/removed branches, so we need to
            // use the new version.
            if (repo) {
                setActiveRepoFn(repo)
            } else {
                setActiveRepoFn(loadedRepos[0])
            }
        } else {
            setActiveRepoFn(undefined);
        }
    }

    const login = (authType: AuthType): string => {
        const params = qs.stringify({auth_type: authType, redirect_to: window.location.href});
        return `${SOLVER_INTERFACE_URL_BASE}/login?${params}`;
    }

    const logout = async (): Promise<SolverInterfaceStatus> => {
        try {
            setSolverInterfaceStatus(SolverInterfaceStatus.LOADING);
            return axios.post(`${SOLVER_INTERFACE_URL_BASE}/logout`, {}, { withCredentials: true }).then(() => {
                setSolverInterfaceStatus(SolverInterfaceStatus.LOGGED_OUT)
                return SolverInterfaceStatus.LOGGED_OUT
            });
        } catch (error) {
            return SolverInterfaceStatus.ERROR;
        }
    };

    const value = {
        solverInterfaceStatus,
        loggedIn,
        appIsReady,
        currentUser,
        login,
        logout,
        connectToSolverInterface,
        repos,
        loadingRepos,
        activeRepo,
        setActiveRepo: setActiveRepoFn,
        addSolverInterfaceEventObserver,
        removeSolverInterfaceEventObserver,
        onStreamConnectionErrorResponse,
        onStreamReconnectionFailed,
    }

    return (
        <SolverInterfaceContext.Provider value={value}>
            {contextHolder}
            {children}
        </SolverInterfaceContext.Provider>
    )
}

const useSolverInterfaceContext = () => {
    const ctx = React.useContext(SolverInterfaceContext);
    if (!ctx) {
        throw new Error("useSolverInterfaceContext must be used within an SolverInterfaceContextProvider");
    }
    return ctx;
}

const getUser = (): Promise<GetUserResult> => {
    return axios.get(
        `${SOLVER_INTERFACE_URL_BASE}/user`,
        { withCredentials: true }
    ).then(response => {
        const user: User = response.data as User;

        return {
            user,
            solverInterfaceStatus: user.allowlisted ? SolverInterfaceStatus.LOGGED_IN : SolverInterfaceStatus.USER_NOT_ALLOWLISTED
        };
    }).catch((error) => {
        if (error.response?.status === 401) {
            return {
                user: undefined,
                solverInterfaceStatus: SolverInterfaceStatus.LOGGED_OUT
            }
        }

        console.error("Error getting user", error);
        return {
            user: undefined,
            solverInterfaceStatus: SolverInterfaceStatus.ERROR
        }
    });
}

export {
    SolverInterfaceContextProvider,
    useSolverInterfaceContext,
}
