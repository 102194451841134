import React, {useEffect} from "react";
import { App, Layout } from "antd";
import "react-diff-view/style/index.css";
import SessionsSider from "./components/SessionsSider";
import ActiveSession from "./components/ActiveSession";
import SolverHeader from "./components/SolverHeader";
import Startup from "./components/Startup";
import { useSolverInterfaceContext } from "./data/SolverInterface";

let appDidInit = false;

const SolverApp: React.FC = () => {
    const { loggedIn, connectToSolverInterface } = useSolverInterfaceContext();

    useEffect(
        () => {
            // Only load the login status once since connectToSolverInterface() can
            // POST if the user is arriving with an OAuth code.
            if (appDidInit) return;

            appDidInit = true;
            // This should be the only method from the SolverInterfaceContext that we call here.
            connectToSolverInterface();
        // Disabling exhaustive-deps because we don't want to re-run this effect on every render.
        }, []
    );

    const buildMainLayout = () => {
        if (!loggedIn) {
            return <Startup/>
        } else {
            return (
                <Layout>
                    <SessionsSider/>
                    <ActiveSession/>
                </Layout>
            )
        }
    }

    return (
        <App>
            <SolverHeader/>
            {buildMainLayout()}
        </App>
    );
};

export default SolverApp;
