export type User = {
    id: string;
    auth_type: AuthType;
    name: string;
    avatar_url: string;
    allowlisted: boolean;
}

export enum AuthType {
    GitHub = "github",
    GitLab = "gitlab",
}

export const pullRequestUrl = (authType: AuthType, repo: string, prNumber: number) => {
    switch (authType) {
        case AuthType.GitHub:
            return `https://github.com/${repo}/pull/${prNumber}`;
        case AuthType.GitLab:
            return `https://gitlab.com/${repo}/-/merge_requests/${prNumber}`;
    }
}

export const revisionUrl = (authType: AuthType, repo: string, revision: string) => {
    switch (authType) {
        case AuthType.GitHub:
            return `https://github.com/${repo}/tree/${revision}`;
        case AuthType.GitLab:
            return `https://gitlab.com/${repo}/-/tree/${revision}`;
    }
}
